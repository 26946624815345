import React, { useEffect, useState } from 'react';
import { GetHomePage } from '../service/api';
import { Link } from 'react-router-dom';

export const Spe_offer = () => {
  const[offerData , setOfferData] = useState({})
  useEffect(()=> {
    const fetOfferData = async()=> {
      const response = await GetHomePage()
      const offer = response.data.Data.Offer[0];
      setOfferData(offer)
    }
    fetOfferData()
  },[])

    return (
        <div className="spe_offer_wrapper" style={{paddingTop:'80px'}}>
        <div className="container">
          <div className="spe_offer">
            <div className="spe_offer_img">
            <img
                src={
                  "https://api.bebranded.com.au/" +
                 offerData.background_image
                }
                alt='bg-img'
              />
            </div>
            <div className="spe_offer_content">
              <div className="spe_offer_logo">
              <img
                  src={
                    "https://api.bebranded.com.au/" +
                    offerData.Logo_image
                  }
                  alt='logo_image'
                />
              </div>
              <div className="spe_offer_title">
                <h3>
                <span
                    dangerouslySetInnerHTML={{
                      __html:offerData.Title,
                    }}
                  />
                </h3>
              </div>
              <div className="spe_offer_para">
              <span
                    dangerouslySetInnerHTML={{
                      __html: offerData.Description,
                    }}
                  />
              </div>
              <Link to={offerData.button_link} className="spe_offer_button">
                <button>Discover more</button>
              </Link>
              <img src="../sp_obj.svg" className="spe_obj"/>
              <img src="../sp_obj2.svg" className="spe_obj2"/>
              <img src="../sp_obj3.svg" className="spe_obj3"/>
            </div>
          </div>
        </div>
      </div>
  );
};
