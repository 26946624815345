import React from "react";
import { Link } from 'react-router-dom';
import { Footer } from "../components/Footer.js";
import Head_CateHead from "../components/Head_CateHead.js";

const NotFoundPage = () => {
  return (
    <div>
      <Head_CateHead />

      <div id="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>
          <h4 data-text="Opps! Page not found">
            Opps! Page not found
          </h4>
          <p>
            Sorry, the page you're looking for doesn't exist.
          </p>
          <div className="trend_bottom_btn">
            <Link to="/">Return Home</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
