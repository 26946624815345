import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ crumbs }) => {
    // Inline styles for breadcrumb components
    const breadcrumbStyle = {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: '#000',
        marginTop: '20px',
        marginLeft: '10px'
    };

    const breadcrumbItemStyle = {
        display: 'inline-block',
        marginRight: '5px',
    };

    const linkStyle = {
        textDecoration: 'none',
        color: '#01AEF0',
    };

    const separatorStyle = {
        marginRight: '5px',
        color: '#888',
    };

    return (
        <nav style={breadcrumbStyle}>
            {crumbs.map((crumb, index) => (
                <span key={index} style={breadcrumbItemStyle}>
                    {index !== crumbs.length - 1 ? (
                        <>
                            <Link to={crumb.path} style={linkStyle}>{crumb.label}</Link>
                            <span style={separatorStyle}> &gt; </span> {/* Separator */}
                        </>
                    ) : (
                        <span>{crumb.label}</span>  // Last crumb is not clickable
                    )}
                </span>
            ))}
        </nav>
    );
};

export default Breadcrumb;
