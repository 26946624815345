import React, { useState, useEffect } from 'react';
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
// import { Category_Header } from "./../components/category-header.js";
import logo from './../assets/img/Logo.png';
import { CheckBox } from '@mui/icons-material';
import { Form, FormProvider, useForm } from "react-hook-form";
import Validation from '../components/validation.js';
import { giveaway_api } from "../service/api.js";
import { redirect, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';

export const Giveaway = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);

  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError(ExceptionError => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  function handleExceptionSuccessMessages(res) {
    setSuccessMessages(successMessages => [
      ...successMessages,
      { id: Date.now(), message: res.data.Message },
    ]);
  }

  function clearErrors(id) {
    setExceptionError(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }

  const handleClick = (event) => {
    event.preventDefault();
    setIsActive(!isActive);
    if (values.interest.indexOf('SUBLIMATED APPAREL') === -1) {
      values.interest.push('SUBLIMATED APPAREL');
    } else {
      values.interest.splice(values.interest.indexOf('SUBLIMATED APPAREL'), 1);
    }
    setValues(values);
  };
  const handleClick2 = (event) => {
    event.preventDefault();
    setIsActive2(!isActive2);
    if (values.interest.indexOf('SIGNAGE AND MARQUEES') === -1) {
      values.interest.push('SIGNAGE AND MARQUEES');
    } else {
      values.interest.splice(values.interest.indexOf('SIGNAGE AND MARQUEES'), 1);
    }
    setValues(values);
  };
  const handleClick3 = (event) => {
    event.preventDefault();
    setIsActive3(!isActive3);
    if (values.interest.indexOf('MERCHANDISE') === -1) {
      values.interest.push('MERCHANDISE');
    } else {
      values.interest.splice(values.interest.indexOf('MERCHANDISE'), 1);
    }
    setValues(values);
  };
  const handleClick4 = (event) => {
    event.preventDefault();
    setIsActive4(!isActive4);
    if (values.interest.indexOf('ALL OF THE ABOVE') === -1) {
      values.interest.push('ALL OF THE ABOVE');
    } else {
      values.interest.splice(values.interest.indexOf('ALL OF THE ABOVE'), 1);
    }
    setValues(values);
  };
  const handleClick5 = (event) => {
    event.preventDefault();
    setIsActive5(!isActive5);
    values.newslatter = isActive5 ? "No" : "Yes";
    setValues(values);
  };
  const [values, setValues] = useState(
    {
      full_name: '',
      school_name: '',
      position: '',
      giv_email: '',
      phone_number: '',
      interest: [],
      newslatter: "No",
    }
  )

  function handleInput(event) {
    const newObj = { ...values, [event.target.name]: event.target.value }
    setValues(newObj)
  }
  
  const giveaway_api_call = async (values) => {
    if(values.full_name != ''){
      const res = await giveaway_api(values);

      if (res.status == 200) {
        var data = res.data;
        console.log(data);
        handleExceptionSuccessMessages(res);
        setTimeout(() => {
          window.location.href = 'https://www.bebranded.com.au/agencyproduct/534';
          }, 2500);
      } else {
        handleExceptionError(res.response.data.ErrorMessage)
      }
      return (data);
    }
  };
  const [errors, setErrors] = useState({})

  function handleValidation(event) {
    event.preventDefault();
    const validationErrors = Validation(values); // Pass the values object directly
    setErrors(validationErrors);
  }
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      giveaway_api_call(values);
    }
  }, [errors]);

  return (
    <div>
      <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      {/* {showPopup && (
        <div className="popup-wrapper">
          <div className='popup'>
          <p>Form submitted</p>
          </div>
        </div>
      )}  */}
      {/* <Header/> */}
      {/* <Category_Header /> */}
      <div className="giveaway_wrapper">
        <div className="giveaway_header">
          <img src="img/give/BB.png" />
        </div>
        <div className="giv_form_bg">
          <img src="img/give/BB-1.png" />
        </div>
        <form onSubmit={handleValidation}>
          <div className="inout_field">
            <label>FULL NAME</label>
            <input type="text" name="full_name" id="full_name" onChange={handleInput} />
            {errors.nameerr && <p className='giv_error'>{errors.nameerr}</p>}
          </div>
          <div className="inout_field">
            <label>SCHOOL NAME</label>
            <input type="text" name="school_name" id="school_name" onChange={handleInput} />
            {errors.schoolerr && <p className='giv_error'>{errors.schoolerr}</p>}
          </div>
          <div className="inout_field">
            <label>POSITION</label>
            <input type="text" name="position" id="position" onChange={handleInput} />
            {errors.poerr && <p className='giv_error'>{errors.poerr}</p>}
          </div>
          <div className="inout_field">
            <label>EMAIL ADDRESS</label>
            <input type="text" name="giv_email" id="give_email" onChange={handleInput} />
            {errors.emailerr && <p className='giv_error'>{errors.emailerr}</p>}
          </div>
          <div className="inout_field">
            <label>PHONE NUMBER</label>
            <input type="tell" name="phone_number" id="phone_number" onChange={handleInput} />
            {errors.numerr && <p className='giv_error'>{errors.numerr}</p>}
          </div>
          <div className='form_para'>
            <p>WHAT ITEMS INTERESTED YOU THE<br />MOST ON OUR STALL?</p>
            <p>&#40;PLEASE SELECT AS MANY AS YOU LIKE&#41;</p>
          </div>
          {errors.interesterr && <p className='giv_error mulsel'>{errors.interesterr}</p>}
          <div className="inout_button">
            <button key="button1"
              onClick={handleClick}
              className={`${isActive ? "active" : ""}`}>SUBLIMATED APPAREL</button>
            <button
              key="button2"
              onClick={handleClick2}
              className={`${isActive2 ? "active" : ""}`}>SIGNAGE AND MARQUEES</button>
            <button
              key="button3"
              onClick={handleClick3}
              className={`${isActive3 ? "active" : ""}`}>MERCHANDISE</button>
            <button
              key="button4"
              onClick={handleClick4}
              className={`${isActive4 ? "active" : ""}`}>ALL OF THE ABOVE</button>
          </div>
          <div className="para_button">
            <button key="button4"
              onClick={handleClick5}
              className={`${isActive5 ? "active" : ""}`}>PLEASE SIGN ME UP TO THE NEWSLETTER AND KEEP ME
              INFORMED OF ANY SPECIALS BEBRANDED.COM.AU IS
              RUNNING.
            </button>
          </div>
          <div className="inout_field">
            <input type="submit" value="SUBMIT ENTRY" />
          </div>
        </form>


        <div className="giveaway_footer">
          <div className='footer_social_img'>
            <Link to="https://www.instagram.com/_bebranded/"><img src="img/give/BB-2.png" /></Link>
          </div>
          <div className='footer_logo_img'>
            <img src="img/give/BB-3.png" />
          </div>
          <div className="give_footer_content">
            <p>Drawn Saturday 12/8/23, marquee includes full custom roof, Frame, carry bag and delivery to your school. all entries must complete the ENTRY<br />
              form in full , LIKE BEBRANDED ON INSTAGRAM AND COMMENT ON THE WIN A MARQUEE POST to be eligible.</p>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  );
}