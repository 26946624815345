import React, { useEffect } from "react";
import { addtocart_delete_api } from "../service/api";
import Internet from "../assets/img/Internet.svg";

const Alert = ({
  message,
  showConfirmBox,
  setShowConfirmBox,
  onConfirm,
  id,
  onClose,
}) => {
  useEffect(() => {
    if (showConfirmBox) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "auto";
    }

    return () => {
      document.documentElement.style.overflowY = "auto";
    };
  }, [showConfirmBox]);

  return (
    <>
      {showConfirmBox && (
        <div>
          <div className="backdrop-alert"></div>

          <div className="container-confirm">
           

            <div className="confirmation-text">{message}</div>
            <div className="button-container">
              <button
                className="cancel-button"
                onClick={() => {
                  onConfirm(id);
                }}
              >
                OK
              </button>
              <button
                className="confirmation-button"
                onClick={() => {
                  setShowConfirmBox(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
