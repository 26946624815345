import React, { useEffect } from "react";


const Modal = ({ modalOpen, setModalOpen , children }) => {
  useEffect(() => {
    if (modalOpen) {
      
      document.documentElement.style.overflowY = 'hidden';
    } else {
     
      document.documentElement.style.overflowY = 'auto';
     
    }
 
    return () => {
      
      document.documentElement.style.overflowY = 'auto';
    };
  }, [modalOpen]);
  return (
    <>
  
        <div className="whole_area_md">
          <div className="backdrop_md" onClick={()=>setModalOpen(false)}></div>
          <div className="modal_container_md"> {children}</div>
        </div>

    </>
  );
};

export default Modal;