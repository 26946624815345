import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from "react-router-dom";

import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import { Account_head } from "./../components/account_head.js";
import { billind_address_list } from "../service/api.js";
import { sipping_address_list } from "../service/api.js";
import { del_billing_add } from "../service/api.js";
import { del_shipping_add } from "../service/api.js";
import { Remove_btn } from "./../components/remove_button.js";
import { LocalPostOffice } from '@mui/icons-material';
import Head_CateHead from '../components/Head_CateHead.js';

export const Acc_address = () => {
    const navigate = useNavigate();
    const [Baddress_list, setBaddress] = useState([]);
    const [Saddress_list, setSaddress] = useState([]);
    const [_id, set_id] = useState(0);
    // const [modalIsOpen, setIsOpen] = useState(false);
   
    useEffect(() => {

        if(!localStorage.getItem('token')){
            navigate("/signin");   
          }
        const SfetchData = async () => {
            try {
                const res = await billind_address_list();
                setBaddress(res.data.Data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const BfetchData = async () => {
            try {
                const res = await sipping_address_list();
                setSaddress(res.data.Data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        SfetchData();
        BfetchData();
    }, [_id]); 
    const handleRemove = async (id) => {
        const res = await del_billing_add(id);
        if (res.status == 200) {
            setBaddress(prevAddresses => prevAddresses.filter(item => item.UserBillingAddressID !== id));
            set_id(id);
            
        }
    };
    const handleUpdate = async (id) => {
        navigate(`/billing_address/${id}`);
    };
    const handleSUpdate = async (id) => {
        navigate(`/shipping_address/${id}`);
    };
    const handleSRemove = async (id) => {
        const res = await del_shipping_add(id);
        if (res.status == 200) {
            setSaddress(prevAddresses => prevAddresses.filter(item => item.UserShippingAddressID !== id));
            set_id(id);
        }
    };
    return(
        <div>
            <Head_CateHead/>
            <Account_head/>
            <div class="sign_in_wrapper container account">
                
               <div className='address_wrapper'>
                    <div className='billing_address_list'>
                        <div className="billing_address">
                            <div className="shipping_address_title">
                                <h3>Billing Address</h3>
                            </div>
                        </div>
                   {Baddress_list.map((item, index) => (
                        <>
                        <div className="billing_address_box" key={index}>
                            <p className='address_num'>{index+1}</p>
                            <p className='address'>{item.BAddress1} {item.BCity} {item.BState} {item.BCountry}</p>
                            <div class="order_box_delete">
                                <button style={{backgroundColor: 'red'}} onClick={() => handleRemove(item.UserBillingAddressID)}>Remove</button>
                            </div>
                            <div class="order_box_delete">
                                <button style={{backgroundColor: '#01AEF0'}} onClick={() => handleUpdate(item.UserBillingAddressID)}>Update</button>
                            </div>
                        </div>
                        <hr/>
                        </>
                ))}
<Link to="/billing_address">
                        <Remove_btn text="Add" bgcolor="#01AEF0"/>
                        </Link>
                    </div>
                    <div className='shipping_address_list'>
                        <div className="shipping_address">
                            <div className="shipping_address_title">
                                <h3>Shipping Address</h3>
                            </div>
                        </div>
                        {Saddress_list.map((item, index) => (
                        <>
                        <div className="billing_address_box" key={index}>
                            <p className='address_num'>{index+1}</p>
                            <p className='address'>{item.SAddress1} {item.SCity} {item.SState} {item.SCountry}</p>
                            <div class="order_box_delete">
                                <button style={{backgroundColor: 'red'}} onClick={() => handleSRemove(item.UserShippingAddressID)}>Remove</button>
                            </div>
                            <div class="order_box_delete">
                                <button style={{backgroundColor: '#01AEF0'}} onClick={() => handleSUpdate(item.UserShippingAddressID)}>Update</button>
                            </div>
                        </div>
                        <hr/>
                        </>
                ))}
<Link to="/shipping_address">

                        <Remove_btn text="Add" bgcolor="#01AEF0"/>
                        </Link>
                    </div>
               </div>
            </div>
            <Footer />
        </div>
    );  
}