export const BRANDING_SOLUTIONS = [
    {
      id: "1",
      title: "Colourflex Transfer",
      Slug: "#",
      Description:
        "Colourflex® is a CMYK+W digital print process, used for branding apparel and fabrics.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/colourflex-transfer.png",
      Advantages_list: [
        {
          id: 1,
          data: "Ideal for producing full colour, complex images as well as approximate spot colour branding.",
        },
        {
          id: 2,
          data: "High definition, vibrant matt finish artwork that is suitable on a range of garment fabrics.",
        },
        {
          id: 3,
          data: "Eco-friendly water-based inks.",
        },
        {
          id: 4,
          data: "Durable, flexible branding for apparel that is machine washable with a soft-touch matt finish.",
        },
        {
          id: 5,
          data: "Efficient self-weeding technology.",
        },
        {
          id: 6,
          data: "Only one set up charge is required regardless of the number of print colours.",
        },
        {
          id: 7,
          data: "Can be shipped straight after being printed.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Some colours cannot be reproduced including metallic and neon/fluorescent colours.",
        },
        {
          id: 2,
          data: "A thin, clear line of glue can sometimes be seen around the edges of the image.",
        },
        {
          id: 3,
          data: "Unable to print variable data.",
        },
        {
          id: 4,
          data: "Minimum detail advised at 1mm.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/colourflex-transfer.png",
    },
    {
      id: "2",
      title: "Debossing",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/debossing.png",
      Description:
        "Commonly referred to as ‘Blind’ debossing, a heated custom metal plate is pressed firmly onto the product leaving an impression of the artwork. Thermo debossing is also available on certain products, using additional heat to create a unique and eye-catching two-tone finish.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/debossing.png",
      Advantages_list: [
        {
          id: 1,
          data: "Higher perceived value than other forms of branding.",
        },
        {
          id: 2,
          data: "The branding becomes part of the product and is permanent.",
        },
        {
          id: 3,
          data: "The product can be shipped as soon as heat pressing is finished.",
        },
        {
          id: 4,
          data: "Certain products can produce a two-tone finish.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Unable to print variable data such as individual naming.",
        },
        {
          id: 2,
          data: "Minimum line thickness: 0.4mm.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "The artwork must be supplied in vector format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "3",
      title: "Digital Label",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/digital-label.png",
      Description:
        "Digital adhesive labels are used to brand products that cannot be branded with any other method. They are printed with a digital printing press and applied to the product.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/digital-label.png",
      Advantages_list: [
        {
          id: 1,
          data: "Ideal for producing vivid full colour images as well as closely matched spot colour branding.",
        },
        {
          id: 2,
          data: "Can print variable data including individual names.",
        },
        {
          id: 3,
          data: "Only one set up charge is required regardless of the number of print colours.",
        },
        {
          id: 4,
          data: "Edge-to-edge branding can be achieved.",
        },
        {
          id: 5,
          data: "Can be cut to custom shapes.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Metallic and neon/fluorescent colours are not available.",
        },
        {
          id: 2,
          data: "White print cannot be produced on clear, silver or gold stock.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "A 3mm bleed should be added to the artwork if it bleeds off the product.",
        },
      ],
    },
    {
      id: "4",
      title: "Digital Packaging Print",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/digital-packaging-print.png",
      Description:
        "Digital Packaging Print offers a single-pass CMYK digital print onto a range of materials.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/digital-packaging-print.png",
      Advantages_list: [
        {
          id: 1,
          data: "Ideal for producing full colour, complex images with gradients.",
        },
        {
          id: 2,
          data: "Variable data, including names, is available on select products.",
        },
        {
          id: 3,
          data: "Only one setup charge is required regardless of the number of print colours.",
        },
        {
          id: 4,
          data: "Dries instantly, so products can be shipped immediately.",
        },
        {
          id: 5,
          data: "Digital Packaging Print uses dye so there is no ink build-up.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Some colours, including closely matched PMS, RGB, metallic and neon colours, cannot be produced.",
        },
        {
          id: 2,
          data: "Cannot print on dark coloured substrates as white ink cannot be printed underneath the artwork.",
        },
        {
          id: 3,
          data: "Edge-to-edge branding and large block colours cannot be achieved.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "Fonts should be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "5",
      title: "Digital Print",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/digital-print.png",
      Description:
        "This production method is used for printing media such as paper, vinyl and magnetic material used in the manufacture of labels, badges, and fridge magnets etc. This printing process uses CMYK values.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/digital-print.png",
      Advantages_list: [
        {
          id: 1,
          data: "Ideal for producing full colour, complex images with gradients.",
        },
        {
          id: 2,
          data: "Variable data including individual names is available on select products.",
        },
        {
          id: 3,
          data: "Only one set up charge is required regardless of the number of print colours.",
        },
        {
          id: 4,
          data: "Dries instantly so products can be shipped immediately.",
        },
        {
          id: 5,
          data: "Select products can be cut to custom shapes.",
        },
        {
          id: 6,
          data: "Edge-to-edge branding can be achieved on certain products.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Metallic and neon/fluorescent colours are not available.",
        },
        {
          id: 2,
          data: "White print cannot be produced on kraft, clear, silver or gold stock.",
        },
        {
          id: 3,
          data: "Cannot print on dark coloured substrates as white ink cannot be printed underneath the artwork",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "A 3mm bleed should be added to the artwork if it bleeds off the product.",
        },
      ],
    },
    {
      id: "6",
      title: "Direct Digital",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/direct-digital.png",
      Description:
        "Direct digital printing involves the transfer of ink directly from the print heads of an inkjet machine to the product and can be used to produce both full colour and closely matched spot colour branding on flat or slightly curved surfaces.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/direct-digital.png",
      Advantages_list: [
        {
          id: 1,
          data: "Ideal for printing dark coloured products as a layer of white ink can be printed under the artwork.",
        },
        {
          id: 2,
          data: "Variable data including individual names is available on select products.",
        },
        {
          id: 3,
          data: "Dried instantly so products can be shipped immediately.",
        },
        {
          id: 4,
          data: "Offers larger print areas on many products and can print very close to the edge of flat products.",
        },
        {
          id: 5,
          data: "Only one set up charge is required regardless of the number of print colours.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Some colours cannot be reproduced including metallic and neon/fluorescent colours.",
        },
        {
          id: 2,
          data: "The size of branding areas is limited on curved surfaces.",
        },
        {
          id: 3,
          data: "Larger print areas can be more expensive.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "A 3mm bleed must be added to the artwork if it bleeds off the product.",
        },
      ],
    },
    {
      id: "7",
      title: "Embroidery",
      Slug: "#",
      modal_slug: "See our thread colour chart",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/embroidery.png",
      Description:
        "Embroidery is an excellent way of branding bags, apparel, and other textile products. It offers higher perceived value and a depth of branding quality which other processes cannot match. Embroidery uses rayon thread which is stitched into the product and has a slightly raised effect.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/embroidery.png",
      Advantages_list: [
        {
          id: 1,
          data: "Only one setup charge applies per position for up to 12 thread colours.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Only approximate PMS colour matches are possible - the threads to be used are chosen from those available to give the closest possible match. See our thread colour chart for the colours available.",
        },
        {
          id: 2,
          data: "Unable to print variable data such as individual naming.",
        },
        {
          id: 3,
          data: "Metallic embroidery colours have special pricing.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork must be supplied in editable vector format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
        {
          id: 3,
          data: "We recommend avoiding fine detail - the minimum text heights for embroidered artworks are:",
          sub_data_list: [
            {
              id: 1,
              sub_data: "4.5mm for Sans Serif fonts",
            },
            {
              id: 1,
              sub_data: "5.5mm for Serif fonts",
            },
          ],
        },
      ],
    },
    {
      id: "8",
      title: "Foil Printing",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/foil-printing.png",
      Description:
        "Foil Printing is a printing method that applies a metallic foil onto a flat surface using heat and pressure. However, this method utilises modern digital foiling, which is more cost-effective and eliminates the need for plates or stamps, allowing for direct printing from a digital file to the product.",
      Description2:
        "The printing will inherit the texture of the substrate, so textured notebooks will cause the foil to reflect that texture. The foil finish will vary slightly when applied to soft-touch PU compared to Standard PU due to the different substrates. The available foil colour options include Gold, Silver, and Copper.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/foil-printing.png",
      Advantages_list: [
        {
          id: 1,
          data: "Higher perceived value than other forms of branding.",
        },
        {
          id: 2,
          data: "The product can be shipped as soon as printing is finished.",
        },
        {
          id: 3,
          data: "Offers variable data such as individual naming.",
        },
        {
          id: 4,
          data: "The foil is flexible which means it is suitable for softcover notebooks.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Detailed artwork can get lost on textured substrates.",
        },
        {
          id: 2,
          data: "Min line thickness 0.4mm for smooth substrates.",
        },
        {
          id: 3,
          data: "Min line thickness 1mm for textured substrates.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "The artwork must be supplied in vector format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "9",
      title: "Hot Stamping",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/hot-stamping.png",
      Description:
        "Hot Stamping is a printing method that creates an indented design on the product surface using a heated custom metal plate. This plate is pressed firmly onto the product surface, leaving a permanent impression of the artwork. It's often used on materials like wood",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/hot-stamping.png",
      Advantages_list: [
        {
          id: 1,
          data: "Higher perceived value than other forms of branding.",
        },
        {
          id: 2,
          data: "The branding becomes part of the product and is permanent.",
        },
        {
          id: 3,
          data: "The product can be shipped as soon as heat pressing is finished.",
        },
        {
          id: 4,
          data: "Certain products can produce a two-tone, contrasting finish.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Unable to print variable data such as individual naming.",
        },
        {
          id: 2,
          data: "Large block artwork can produce an inconsistent print result.",
        },
        {
          id: 3,
          data: "Minimum line thickness: 0.4mm.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "The artwork must be supplied in vector format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "10",
      title: "Imitation Etch",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/imitation-etch.png",
      Description:
        "Imitation etch uses a special ink in to produce an etch-like effect on glass products via pad or screen print.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/imitation-etch.png",
      Advantages_list: [
        {
          id: 1,
          data: "Much more affordable than real etching.",
        },
        {
          id: 2,
          data: "Can brand curved or uneven products.",
        },
        {
          id: 3,
          data: "Produces a subtle finish with a higher perceived value that looks like etching.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Halftones cannot be consistently reproduced.",
        },
        {
          id: 2,
          data: "The size of branding areas is limited on curved surfaces.",
        },
        {
          id: 3,
          data: "Requires a curing period before the product can be shipped.",
        },
        {
          id: 4,
          data: "Unable to print variable data.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork must be supplied in a vector editable format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "11",
      title: "Laser Engraving",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/laser-engraving.png",
      Description:
        "Laser engraving is a permanent branding process that engraves artwork into the surface of the product using a laser. Different materials produce different engraving finishes, to avoid uncertainty pre-production samples are recommended.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/laser-engraving.png",
      Advantages_list: [
        {
          id: 1,
          data: "Higher perceived value than other forms of branding.",
        },
        {
          id: 2,
          data: "The branding becomes part of the surface and is permanent.",
        },
        {
          id: 3,
          data: "Large branding areas available on curved products.",
        },
        {
          id: 4,
          data: "Variable data including individual names is available on select products.",
        },
        {
          id: 5,
          data: "The product can be shipped straight away after being engraved.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Fine detail can be lost on smaller products like pens.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork must be supplied in editable vector format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "12",
      title: "Pad Print",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/pad-print.png",
      Description:
        "Pad printing uses a silicone pad to transfer an image to a product from a laser etched printing plate. It is one of the most popular and affordable ways of branding promotional products due to its ability to reproduce images on uneven or curved products and print multiple colours in a single pass.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/pad-print.png",
      Advantages_list: [
        {
          id: 1,
          data: "Ideal for printing on curved or uneven products.",
        },
        {
          id: 2,
          data: "Prints sharp, clean images with detail as fine as 0.4mm, minimum text size is 5pt.",
        },
        {
          id: 3,
          data: "Close PMS matches are possible on white or light-coloured products.",
        },
        {
          id: 4,
          data: "Metallic gold and silver ink are available.",
        },
        {
          id: 5,
          data: "Can offer a white under base for the darker products that require it.",
        },
        {
          id: 6,
          data: "Many products can print up to 6 colours with tight multi-colour registration.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Halftones cannot be consistently reproduced.",
        },
        {
          id: 2,
          data: "Unable to print variable data such as individual naming.",
        },
        {
          id: 3,
          data: "Close PMS matches are more difficult on darker products and will only be approximate. In these instances, a white base will create a better print.",
        },
        {
          id: 4,
          data: "Some pad print inks require a curing period before the product can be shipped.",
        },
        {
          id: 5,
          data: "Each colour requires its own setup charge.",
        },
        {
          id: 6,
          data: "Neon/fluorescent colours are not available.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork must be supplied in a vector editable format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "13",
      title: "Resin Coated Finish",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/resin-coated-finish.png",
      Description:
        "This CMYK branding process is produced by printing artwork onto a vinyl material with strong adhesive on the reverse. The branded area is then coated with a crystal-clear resin. Once dry, the finished decal is applied to the product and the adhesive forms a permanent bond.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/resin-coated-finish.png",
      Advantages_list: [
        {
          id: 1,
          data: "Ideal for producing vivid full colour images as well as closely matched spot colour branding.",
        },
        {
          id: 2,
          data: "Produces a stunning 3D effect that elevates the perceived value of a product.",
        },
        {
          id: 3,
          data: "Variable data including individual names is available on select products.",
        },
        {
          id: 4,
          data: "Edge-to-edge branding can be achieved on the resin coated area.",
        },
        {
          id: 5,
          data: "Only one set up charge is required regardless of the number of print colours.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Larger print areas can be more expensive.",
        },
        {
          id: 2,
          data: "White, metallic gold, silver and neon colours cannot be printed.",
        },
        {
          id: 3,
          data: "The resin needs to be cured for one day before shipping the product.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "A 3mm bleed must be added to the artwork if it bleeds off the product.",
        },
      ],
    },
    {
      id: "14",
      title: "Rotary Digital Print",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/rotary-digital-print.png",
      Description:
        "Direct to product rotary digital printing involves the transfer of UV ink and a varnish coating from inkjet print heads and can be used to produce detailed gloss artwork using both closely matched spot colours and full colour branding.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/rotary-digital-print.png",
      Advantages_list: [
        {
          id: 1,
          data: "Ideal for large or complex full colour gloss prints.",
        },
        {
          id: 2,
          data: "The print is dry and ready to ship as soon as the product is printed.",
        },
        {
          id: 3,
          data: "Only one setup charge is required regardless of the number of print colours.",
        },
        {
          id: 4,
          data: "No loss of print vibrancy, even on darker products.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Production speed is limited so lead times can be longer in some cases.",
        },
        {
          id: 2,
          data: "Some colours cannot be reproduced including metallic and neon/fluorescent colours.",
        },
        {
          id: 3,
          data: "As the print does not wrap completely around the product, there is a small gap between the start and end of the print.",
        },
        {
          id: 4,
          data: "More expensive than other branding options.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "15",
      title: "Rotary Screen Print",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/rotary-screen-print.png",
      Description:
        "Rotary Screen Printing is achieved by forcing ink through a fine mesh screen with a squeegee onto the product and is ideal for cylindrical objects.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/rotary-screen-print.png",
      Advantages_list: [
        {
          id: 1,
          data: "Large print areas are possible on cylindrical products.",
        },
        {
          id: 2,
          data: "Close PMS matches are possible on white or light-coloured products.",
        },
        {
          id: 3,
          data: "Most screen print inks are quick drying and can be shipped immediately after printing.",
        },
        {
          id: 4,
          data: "Metallic gold and silver colours are available.",
        },
        {
          id: 5,
          data: "Many products can print five colours (including white) with tight multi-colour registration.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Close PMS matches are more difficult on darker products and will only be approximate. In some instances, a white base will create a better print.",
        },
        {
          id: 2,
          data: "Unable to print variable data.",
        },
        {
          id: 3,
          data: "Each colour requires its own setup charge.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork must be supplied in editable vector format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "16",
      title: "Screen Print",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/screen-print.png",
      Description:
        "Screen Printing is achieved by forcing ink through a fine mesh screen with a squeegee onto the product and is ideal for branding flat objects.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/screen-print.png",
      Advantages_list: [
        {
          id: 1,
          data: "Large print areas are possible on flat products.",
        },
        {
          id: 2,
          data: "Close PMS matches are possible on white or light-coloured products.",
        },
        {
          id: 3,
          data: "Most screen print inks dry quickly and can be shipped immediately after printing.",
        },
        {
          id: 4,
          data: "Fluorescent, metallic gold and silver inks are available on select textile products.",
        },
        {
          id: 5,
          data: "Many products can print with tight multi-colour registration, with some products able to be printed using up to five colours.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Close PMS matches are more difficult on darker products and will only be approximate. In some instances, a white base will create a better print.",
        },
        {
          id: 2,
          data: "Unable to print variable data.",
        },
        {
          id: 3,
          data: "Each colour requires its own setup charge.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork must be supplied in editable vector format.",
        },
        {
          id: 2,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "17",
      title: "Silicone Digital Print",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/silicone-digital-print.png",
      Description:
        "This production method is a CMYK+W digital branding process designed specifically for silicone surfaces.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/silicone-digital-print.png",
      Advantages_list: [
        {
          id: 1,
          data: "Provides crisp, clear, high-definition artwork.",
        },
        {
          id: 2,
          data: "Durable, flexible branding that can be hand washed.",
        },
        {
          id: 3,
          data: "Perfect for printing dark coloured products as a layer of white ink can be printed under the artwork.",
        },
        {
          id: 4,
          data: "Ideal for producing full colour images as well as approximate spot colour branding.",
        },
        {
          id: 5,
          data: "Has a matt finish and does not crack or fade with typical use of product.",
        },
        {
          id: 6,
          data: "Only one set up charge is required regardless of the number of print colours.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Some colours cannot be reproduced including metallic and neon/fluorescent colours.",
        },
        {
          id: 2,
          data: "Unable to print variable data.",
        },
        {
          id: 3,
          data: "Minimum detail is advised to be no finer than 0.7mm.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "Fonts are advised to be converted to outlines/objects to avoid font conflicts.",
        },
      ],
    },
    {
      id: "18",
      title: "Sublimation Print",
      Slug: "#",
      image:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/icons/sublimation-print.png",
      Description:
        "Dye sublimation print is used for branding products that have a special coating on them, or fabrics suitable for the sublimation process. A transfer is produced by printing sublimation ink onto transfer paper and then heat pressing it onto the product.",
      modal_img:
        "https://dwa91yjg30w8v.cloudfront.net/2eeaffa1-8768-4be6-b2b1-07da52b3ff31/images/branding/sublimation-print.png",
      Advantages_list: [
        {
          id: 1,
          data: "Sublimation ink is a dye so there is no ink build-up. The finished print is embedded in the product.",
        },
        {
          id: 2,
          data: "Ideal for producing vivid full colour images as well as closely matched spot colour branding.",
        },
        {
          id: 3,
          data: "Variable data including individual names is available on select products.",
        },
        {
          id: 4,
          data: "Edge-to-edge branding can be achieved on some products.",
        },
        {
          id: 5,
          data: "Only one set up charge is required regardless of the number of print colours.",
        },
      ],
      Limitations_list: [
        {
          id: 1,
          data: "Can only be used on suitable products with white surfaces.",
        },
        {
          id: 2,
          data: "Some colours cannot be reproduced including white, metallic, and neon/fluorescent colours.",
        },
        {
          id: 3,
          data: "Fine detail/text may experience minor colour bleed across adjacent design elements.",
        },
      ],
      Artwork_requirements_list: [
        {
          id: 1,
          data: "Artwork can be supplied in either vector or bitmap format.",
        },
        {
          id: 2,
          data: "Supplied bitmaps must be higher than 300DPI resolution at the actual print size.",
        },
        {
          id: 3,
          data: "A 3mm bleed must be added to the artwork if it bleeds off the product.",
        },
      ],
    },
  ];