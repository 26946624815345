import React, { useState, useEffect } from "react";
import Head_CateHead from "../components/Head_CateHead.js";
import { Slide_title } from "./../components/slide-title.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Footer } from "../components/Footer.js";
import { faq } from "../service/api.js";
const AccordionItem = ({ expanded, onChange, id, header, content }) => (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={expanded ? "-" : "+"}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        className="faqpage"
      >
        <Typography>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails className="faqpage1">
        <Typography>
          <p>{content}</p>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
  


export const Faq = () => {
  const [expanded, setExpanded] = useState("");
  const [updatedAccordionData1, setupdatedAccordionData1] = useState([]);
  const getdata = async () => {
    try {
      const res = await faq();
      const data = res.data.data.Description;
      const updatedAccordionData = data.map((item, index) => {
        const i = index + 1;
        const header = item.Title; // Replace underscores with spaces
        const content = item.Description;
        return {
          id: `pane${i}a`,
          header: header,
          content: content,
        };
      });

      // Now updatedAccordionData has the desired structure
      console.log(updatedAccordionData);
      setupdatedAccordionData1(updatedAccordionData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);



  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };


  const accordionData = [
    {
      id: "pane1a",
      header: "What is the expected turnaround time on my order?",
      content:
        "Our standard dispatch time is 7-10 days from artwork approval and payment receipt.",
    },
    {
        id: "pane2a",
        header: "Why are Be Branded different from other merchandise companies?",
        content:
          "We are a family owned and operated promotional merchandise company who love what we do. We love seeing our client get results, we wont just sell you a product because we make more, we provide you products suited to your business and campaigns.",
      },
      {
        id: "pane3a",
        header: "How do I contact you? Are you available to talk to right now?",
        content:
          "We are here to take your calls from 08:30 am to 17:30 pm Monday to Friday on 0438 799 417. We are frequently in the office outside of these hours and we will answer if we are available. If you are trying to contact us outside of our core hours; then please email Info@bebranded.com.au we do monitor emails out of hours and will get back to you as soon as we receive the email.",
      },
      {
        id: "pane4a",
        header: "I'm not in Queensland- Do you deliver",
        content:
          "Yes, we have many happy clients nationwide, that we regularly deliver merchandise and print media to. We deliver FREE AUSTRALIA WIDE",
      },
      {
        id: "pane5a",
        header: "I can’t find what I am looking for, are you able to source itemsthat aren’t on the website?",
        content:
          "Yes! We source products from across the globe and we are continually updating the website, which means even though a product doesn’t appear on the website, we are generally able to source it for you, and are more than happy to do so!",
      },
      {
        id: "pane6a",
        header: "How will my promotional products be personalised with my logo?",
        content:
          "You will need to supply us with your artwork, this can be sent via e-mail as a PDF, or on disc and by uploading to our website under your account. We accept AI, EPS, PDF. If you are using PhotoShop the file will need to a minimum resolution of 300 dpi and must be supplied at either the final required size or larger. ",
      },
      {
        id: "pane7a",
        header: "What artwork do you require?",
        content:
          "If you are unsure of what artwork we require, our graphic designers are available to advise you. They are also able to produce artwork based on your brief if you do not have any artwork available. Please be aware that we do need high quality artwork and letterheads are insufficient for this purpose. Generally we do not charge for artwork and include up to 3 amends. Detailed design work, re-draws and any further changes may be charged at $80.00 per hour.",
      },
      {
        id: "pane8a",
        header: "Are you able to match the exact colour/s of my logo?",
        content:
          "Yes, of course, we will need you to supply us with a Pantone Matching System (PMS) reference number. The Pantone system is universal and enables all printers to accurately reproduce any colours you specify. You will see that we do have a Pantone Page with these colours available for your use. However, we do strongly recommend that you check your Pantone Reference number against a hard copy, as different computer screens will display the colours dependent upon their resolution and may not be accurate.",
      },
      {
        id: "pane9a",
        header: "Where will I find my artwork and PMS references?",
        content:
          "If you currently have a design and or / logo that you have been using, then these should be stored by the design agency or printers that were originally responsible for the creation of your logo. If these are unavailable then we will need to create new artwork for you, and we will require a sample of your existing merchandise in order that we can match to that unique pantone number. Please be aware that colours will vary depending upon the substrate that they are applied to.",
      },
      {
        id: "pane10a",
        header: "My promo logo incorporates halftone, is it possible for this to be printed?",
        content:
          "Halftones can be reproduced, but this is dependent upon the printing process, we would need to see the logo and then we would be able to offer advice on the best method available to achieve the desired results.",
      },
      {
        id: "pane11a",
        header: "How do I know that my artwork will be reproduced exactly as the original and that my logo will be sized accordingly?",
        content:
          "We will always send you free visuals for your approval before your products are manufactured. If, for any reason, you are not happy at this stage, we will amend the visuals until these meet your requirements, and we will not progress to the production stage until you have given your approval. Occasionally we may order a pre-production sample if we are concerned that the final colour may differ from your specification because of the process being used. Either way, you can be sure that we take very seriously the need to faithfully reproduce your artwork to your complete satisfaction.",
      },
      {
        id: "pane12a",
        header: "What are the setup charges?",
        content:
          "Charges are made for screens, dies and templates used in the production process. For example a four colour print will require four separate screens, so the setup costs will be per screen. We will always letyou know what the setup costs are before you place your order.",
      },
      {
        id: "pane13a",
        header: "Can I order a sample of the promotional items before I purchase them?",
        content:
          "Yes, of course, we are happy to send you plain stock samples of the products so that you can view their quality prior to placing your order. Generally samples are charged for with a standard rate postage and packaging redeemable against any order subsequently placed for that item.",
      },
      {
        id: "pane14a",
        header: "Why should I use Be Branded for my marketing Campaigns?",
        content:
          "Be Branded have been in the Promotional Products industry for over 7 years and continue to expand, with cherry-picked staff and a wealth of experience, Be Branded are here to exceed your expectations on time, every time.",
      },
      {
        id: "pane15a",
        header: "Prices",
        content:
          "We reserve the right to alter designs or prices of products and conditions of supply without notice, but you will be informed before the order is accepted. If the price of the goods you have ordered has changed, the order will not be processed without your consent. For the most up to date prices please call an Account Manager on 0438 799 417.",
      },
    // Add more items as needed
  ];
  return (
    <>
      <Head_CateHead />
      <div className="container faq_wrapper">
        <Slide_title title="FAQ" className="faq_title" />
        <hr />
        
        {updatedAccordionData1.map((item, index) => (
          <AccordionItem
            key={item.id}
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
            {...item}
          />
        ))}
      </div>
      <Footer />
    </>
  );
};
