import React, { useState, useEffect, navigate } from "react";
import { Link, useNavigate } from "react-router-dom";
import search from "./../assets/img/search.svg";
import { ProductBox } from "../components/ProductBox.js";
import Box from "@mui/material/Box";
import {
  AllUnMapcategory,
  home_search,
  portal_map_list,
} from "../service/api.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ContactSupportOutlined } from "@mui/icons-material";
import Modal from "./Modal.js";
import { modalClasses } from "@mui/material";
import { BRANDING_SOLUTIONS } from "./constants.js";



export const Category_Header = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(""); // State for the search input
  const [searchArray, setSearchArray] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [showResults, setShowResults] = useState(false);
  const [portalItems, setportalItems] = useState([]);
  const isAuthenticated = !!localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const portallist = async () => {
      try {
        const response = await portal_map_list(); // Fetch category data
        const ItemsData = response.data.data; // Access the 'Data' array
        setportalItems(ItemsData);
      } catch (error) {
        console.error("Error fetching portal list", error);
      }
    };

    if (localStorage.getItem("token")) {
      portallist(); // Call the function to fetch category data
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearchChange = async (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    try {
      // Make an API request with the search term
      const res = await home_search({ Search: searchTerm });
      const fetchsearchdata = res.data.Data;
      setSearchArray(fetchsearchdata); // Update the search results state with the response data
      setShowResults(true); // Show search results when there's data
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      const searchResultsElement = document.querySelector(".search-container");
      if (
        searchResultsElement &&
        !searchResultsElement.contains(event.target)
      ) {
        setShowResults(false); // Hide search results when clicking outside
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [menuItems, setMenuItems] = useState([]);
  const [menuItems2, setMenuItems2] = useState([]);
  const [menuItemsapperal, setMenuItemsapperal] = useState([]);
  const [MenuItemscollection, setMenuItemscollection] = useState([]);
  const [menuItemsbrand, setmenuItemsbrand] = useState([]);
  useEffect(() => {
    const fetchCategoryItems = async () => {
      try {
        const response = await AllUnMapcategory(); // Fetch category data

        const categoryItemsData = response.data.Data; // Access the 'Data' array

        for (let i = 0; i < categoryItemsData.length; i++) {
          if (categoryItemsData[i].Slug == "apparel") {
            setMenuItemsapperal(response.data.Data[i]);
          }
          if (categoryItemsData[i].Slug == "brands") {
            setmenuItemsbrand(response.data.Data[i]);
          }
          if (categoryItemsData[i].Slug == "collections") {
            setMenuItemscollection(response.data.Data[i]);
          }
        }
        setMenuItems(categoryItemsData);
        setMenuItems2(categoryItemsData);
      } catch (error) {
        console.error("Error fetching category items", error);
      }
    };
    fetchCategoryItems(); // Call the function to fetch category data
  }, []);

  const [openSubmenu, setOpenSubmenu] = useState(null);

  const handleSubmenuToggle = (index) => {
    // Toggle the open/close status of the clicked submenu
    // alert(openSubmenu);
    if (openSubmenu != null) {
      setOpenSubmenu(null);
      // alert(45);
    } else {
      setOpenSubmenu(index);
    }
    // setOpenSubmenu((prevIndex) => (prevIndex === index ? null : index));
  };
  useEffect(() => {
    // Check if there's only one product in the search result
    if (searchArray.Product?.length === 1) {
      const slug = searchArray.Product[0].Slug;
      console.log("Navigating to:", `/product/${slug}`);
      // navigate(`/product/${slug}`);
      window.location.href = `/product/${slug}`;
    }
  }, [searchArray, navigate]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      navigate(`/search/${searchTerm}`);
    }
  };
  const [modalData, setModalData] = useState({});

  const handleShowDetail = (id) => {
    setShowModal(!showModal);
    console.log("modal opened", id);
    const modaldata = BRANDING_SOLUTIONS.find(
      (item) => Number(item.id) === Number(id)
    );
    setModalData(modaldata);
  };
  return (
    <div className="category-header-wrapper">
      {!isMobile ? (
        <>
          <div className="container">
            <Box
              component="ul"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              className="cate-header-ul"
            >
              <li className="all_products">
                <Link to="/shop">Products</Link>
                <Box
                  component="ul"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="all_products_1 hover_open_menu"
                >
                  {menuItems2.map((menuItem, index) => (
                    <>
                      {menuItem.Slug != "apparel" &&
                        menuItem.Slug != "brands" &&
                        menuItem.Slug != "collections" && (
                          <>
                            <li className="sub_cate_menu_">
                              <Link to={`/category/${menuItem.Slug}`}>
                                {menuItem.CategoryName}
                                <svg
                                  height="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                  ></path>
                                </svg>
                              </Link>
                              <Box
                                component="ul"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="all_products_2 hover_open_menu_1"
                              >
                                {menuItem.SubCategory.map(
                                  (childmenu, index2) => (
                                    <li key={index2}>
                                      <Link
                                        to={`/${menuItem.Slug}/${childmenu.Slug}`}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: childmenu.SubCategoryName,
                                          }}
                                        />
                                      </Link>
                                    </li>
                                  )
                                )}
                              </Box>
                            </li>
                          </>
                        )}
                    </>
                  ))}
                </Box>
              </li>
              <li>
                <>
                  {menuItemsapperal.SubCategory ? (
                    menuItemsapperal.SubCategory.length === 0 ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        <Link to={`/category/${menuItemsapperal.Slug}`}>
                          Apparel
                        </Link>
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        <Link to={`/category/${menuItemsapperal.Slug}`}>
                          Apparel
                        </Link>

                        <ul
                          className="child_menu cate-header-ul all_products_1 hover_open_menu portal apparel_"
                          style={{ marginTop: 0 }}
                        >
                          {menuItemsapperal.SubCategory.map(
                            (menuItem, index) => (
                              <li
                                key={index}
                                className="sub_cate_menu_ apparel"
                              >
                                <Link
                                  to={`/${menuItemsapperal.Slug}/${menuItem.Slug}`}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: menuItem.SubCategoryName,
                                    }}
                                  />
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </li>
                    )
                  ) : (
                    ""
                  )}
                </>
                {/* )} */}
              </li>
              {/* <li>
                                <Link to="/indent">Indent</Link>
                            </li> */}
              <li>
                <Link to="/products/new">New</Link>
              </li>
              <li>
                {/* {/* <Link to="#">Brand</Link>  */}
                {/* {isAuthenticated && ( */}
                <>
                  {menuItemsbrand.SubCategory ? (
                    menuItemsbrand.SubCategory.length === 0 ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        <Link to={`/category/${menuItemsbrand.Slug}`}>
                          Brand
                        </Link>
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        <Link to={`/category/${menuItemsbrand.Slug}`}>
                          Brand
                        </Link>
                        <ul
                          className="child_menu cate-header-ul all_products_1 hover_open_menu portal Brand_"
                          style={{ marginTop: 0 }}
                        >
                          {menuItemsbrand.SubCategory.map((menuItem, index) => (
                            <li key={index} className="sub_cate_menu_ apparel">
                              <Link
                                to={`/${menuItemsbrand.Slug}/${menuItem.Slug}`}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL +
                                    menuItem.SubCategoryImage
                                  }
                                />
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: menuItem.SubCategoryName,
                                  }}
                                />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                  ) : (
                    <></>
                  )}
                </>
                {/* )} */}
              </li>
              <li>
                {/* <Link to="#">Collections</Link> */}
                {/* {isAuthenticated && ( */}
                <>
                  {MenuItemscollection.SubCategory ? (
                    MenuItemscollection.SubCategory.length === 0 ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        <Link to={`/category/${MenuItemscollection.Slug}`}>
                          Collections
                        </Link>
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        <Link to={`/category/${MenuItemscollection.Slug}`}>
                          Collections
                        </Link>

                        <ul
                          className="child_menu cate-header-ul all_products_1 hover_open_menu portal collections_"
                          style={{ marginTop: 0 }}
                        >
                          {MenuItemscollection.SubCategory.map(
                            (menuItem, index) => (
                              <li
                                key={index}
                                className="sub_cate_menu_ apparel"
                              >
                                <Link
                                  to={`/${MenuItemscollection.Slug}/${menuItem.Slug}`}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: menuItem.SubCategoryName,
                                    }}
                                  />
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </li>
                    )
                  ) : (
                    <></>
                  )}
                </>
                {/* )} */}
              </li>
              {/* <li>
                                <Link to="/sale">Sale</Link>
                            </li> */}
              {/* <li> */}
              {isAuthenticated && (
                <>
                  {portalItems.length > 0 ? (
                    portalItems.length === 1 ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        <Link to={`/${portalItems[0].Slug}`}>
                          {portalItems[0].PortalName}
                        </Link>
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        {localStorage.getItem("token") ? (
                          <Link to="#">Portals</Link>
                        ) : (
                          ""
                        )}
                        <ul
                          className="child_menu cate-header-ul all_products_1 hover_open_menu portal"
                          style={{ marginTop: 0 }}
                        >
                          {portalItems.map((menuItem, index) => (
                            <li key={index} className="sub_cate_menu_ p">
                              <Link
                                to={`/${menuItem.Slug}`}
                                style={{ Color: "#555 !important" }}
                              >
                                {menuItem.PortalName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                  ) : (
                    <></>
                  )}
                </>
              )}
              {/* {isAuthenticated && ( */}
                <>
                  {BRANDING_SOLUTIONS.length > 0 ? (
                    BRANDING_SOLUTIONS.length === 1 ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        <Link to={`/${BRANDING_SOLUTIONS[0].Slug}`}>
                          {BRANDING_SOLUTIONS[0].title}
                        </Link>
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer" }}
                        className="all_products"
                      >
                        {localStorage.getItem("token") ? (
                          <Link to="#">Branding Solutions</Link>
                        ) : (
                          <Link to="#">Branding Solutions</Link>
                        )}
                        <ul
                          className="child_menu cate-header-ul all_products_1 hover_open_menu portal Brand_"
                          style={{ marginTop: 0 }}
                        >
                          {BRANDING_SOLUTIONS.map((menuItem, index) => (
                            <li key={index} className="sub_cate_menu_ apparel branding">
                              <Link
                                onClick={() => handleShowDetail(menuItem.id)}
                                style={{ Color: "#555 !important" }}
                              >
                                <img
                                  src={menuItem.image}
                                  style={{
                                    width: "4rem",
                                    height: "4rem",
                                    marginRight: "5px",
                                  }}
                                  alt={menuItem.title}
                                />
                                {menuItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                  ) : (
                    <></>
                  )}
                </>
              {/* )} */}
              {/* </li> */}
              <li style={{ cursor: "pointer" }} className="all_products">
                <Link to="https://blog.bebranded.com.au">
                  <span>Blog</span>
                </Link>
              </li>
            </Box>
            <div className="search-container">
              <div className="search-feild">
                <input
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                />
                <img src={search} className="search-icon" />
              </div>

              {searchTerm && (
                <div
                  className={`search-results ${searchArray.Product?.length === 0 ||
                      searchArray.SubCategory?.length === 0
                      ? "close"
                      : ""
                    }`}
                  style={{ display: showResults ? "block" : "none" }}
                >
                  {searchArray.Product?.length === 0 ||
                    searchArray.SubCategory?.length === 0 ? (
                    <div>No results found.</div>
                  ) : (
                    <ul>
                      {searchArray.Product?.length > 0 && (
                        <li>
                          <strong>Product</strong>
                          <ul>
                            {searchArray.Product.length === 1
                              ? searchArray.Product.map((product, index) => (
                                <li key={index}>
                                  {/* <Link to={`/product/${product.ProductID}`}>ss{product.ProductName}</Link> */}
                                  <Link
                                    to={`/product/${product.Slug}`}
                                    key={index}
                                  >
                                    <ProductBox
                                      title={product.ProductName}
                                      subTitle=""
                                      image={product.ProductImage}
                                      tag_img={product.tag_img}
                                    />
                                    <p
                                      className="sku"
                                      style={{
                                        marginLeft: "17px",
                                        fontSize: "0.83vw",
                                        color: "#252525",
                                      }}
                                    >
                                      SKU: {product.SKU}
                                    </p>
                                  </Link>
                                </li>
                              ))
                              : searchArray.Product.map((product, index) => (
                                <li key={index}>
                                  {/* <Link to={`/product/${product.ProductID}`}>
                                      {product.ProductName}
                                    </Link> */}

                                  <Link
                                    to={`/product/${product.Slug}`}
                                    key={index}
                                  >
                                    <ProductBox
                                      title={product.ProductName}
                                      subTitle=""
                                      image={product.ProductImage}
                                      tag_img={product.tag_img}
                                    />
                                    <p
                                      className="sku"
                                      style={{
                                        marginLeft: "17px",
                                        fontSize: "0.83vw",
                                        color: "#252525",
                                      }}
                                    >
                                      SKU: {product.SKU}
                                    </p>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                      )}
                      {searchArray.SubCategory?.length > 0 && (
                        <li>
                          <strong>Category</strong>
                          <ul>
                            {searchArray.SubCategory.map(
                              (subCategory, index) => (
                                <li key={index}>
                                  {" "}
                                  <Link
                                    to={`/category/${subCategory.SubCategoryID}`}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: subCategory.SubCategoryName,
                                      }}
                                    />
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {showModal && (
        <Modal modalOpen={showModal} setModalOpen={setShowModal}>
          <div className="modal_wrapper">
            <span className="modal_closer" onClick={() => setShowModal(false)}>
              &times;
            </span>

            <div className="modal_title_wrapper">
              <h3>{modalData?.title}</h3>
            </div>
            <div className="modal_image">
              <img src={modalData.modal_img} style={{ width: "100%" }} />
            </div>
            <p className="modal_txt" style={{ marginTop: "7px" }}>
              {modalData?.Description}
            </p>
            {modalData.Description2 && (
              <p className="modal_txt" style={{ marginTop: "7px" }}>
                {modalData?.Description2}
              </p>
            )}

            <div style={{ marginTop: "7px" }}>
              <h3>Advantages</h3>
              <ul className="modal_list_wrapper">
                {modalData.Advantages_list &&
                  modalData.Advantages_list.length > 0 &&
                  modalData.Advantages_list.map((listdata, index) => {
                    return (
                      <li key={listdata.id} className="modal_txt">
                        {listdata.data}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div style={{ marginTop: "7px" }}>
              <h3>Limitations</h3>
              <ul className="modal_list_wrapper">
                {modalData.Limitations_list &&
                  modalData.Limitations_list.length > 0 &&
                  modalData.Limitations_list.map((listdata, index) => {
                    return (
                      <li key={listdata.id} className="modal_txt">
                        {listdata.data}
                      </li>
                    );
                  })}

              </ul>
            </div>
            <div style={{ marginTop: "7px" }}>
              <h3>Artwork Requirements</h3>
              <ul className="modal_list_wrapper">
                {modalData.Artwork_requirements_list &&
                  modalData.Artwork_requirements_list.length > 0 &&
                  modalData.Artwork_requirements_list.map((listdata, index) => {
                    return (
                      <>
                        <li key={listdata.id} className="modal_txt">
                          {listdata.data}
                        </li>
                        {
                          listdata.sub_data_list && listdata.sub_data_list.length > 0 && (
                            <ul style={{ marginLeft: "30px", listStyle: "disc", marginTop: "6px" }}>
                              {
                                listdata.sub_data_list && listdata.sub_data_list.length > 0 && listdata.sub_data_list.map((item, subindex) => {
                                  return (
                                    <li key={item.id} className="modal_txt">
                                      {item.sub_data}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          )
                        }

                      </>

                    );
                  })}
              </ul>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
