import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "../components/slide-title.js";
import { Account_head } from "../components/account_head.js";
import { Remove_btn } from "../components/remove_button.js";
import { add_Saddress } from "../service/api.js";
import { update_Saddress } from "../service/api.js";
import { shipping_address_detail } from "../service/api.js";
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import Head_CateHead from '../components/Head_CateHead.js';

export const Acc_Saddress = () => {
  const navigate = useNavigate();
  const {id} = useParams()

  console.log("id",id)
    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const [submitbutton, setsubmitbutton] = useState(true);
  
    function handleExceptionError(res) {
      // alert("sf");
      setExceptionError(ExceptionError => [
        ...ExceptionError,
        { id: Date.now(), message: res },
      ]);
    }
  
    function handleExceptionSuccessMessages(res) {
      setSuccessMessages(successMessages => [
        ...successMessages,
        { id: Date.now(), message: res.data.Message },
      ]);
    }
  
    function clearErrors(id) {
      setExceptionError(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
    function clearSuccess(id) {
      setSuccessMessages(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
    const [data, setData] = useState({
        SCustomerName: '',
        SCountry: '',
        SState: '',
        SCity: '',
        SAddress1: '',
        SAddress2: '',
        SZipcode:'',
      });
    
      const [validationErrors, setValidationErrors] = useState({
        SCustomerName: '',
        SCountry: '',
        SState: '',
        SCity: '',
        SAddress1: '',
        SAddress2: '',
        SZipcode:'',
      });
      
      const data_get = async () => {

        try {
          const res = await shipping_address_detail(id);
          console.log(res);
          setData({
            SCustomerName: res.data.Data.SCustomerName,
            SCountry: res.data.Data.SCountry,
            SState: res.data.Data.SState,
            SCity: res.data.Data.SCity,
            SAddress1: res.data.Data.SAddress1,
            SAddress2: res.data.Data.SAddress2,
            SZipcode: res.data.SZipcode,
          })
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      useEffect(() => {
        if(!localStorage.getItem('token')){
          navigate("/signin");   
        }
        if (id){
        data_get();
      }
      }, []);

      const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (data.SCustomerName.trim() === '') {
          errors.SCustomerName = 'Fullname is required.';
        }
        if (data.SCountry.trim() === '') {
            errors.SCountry = 'Country is required.';
          }
          if (data.SState.trim() === '') {
            errors.SState = 'State is required.';
          }
          if (data.SCity.trim() === '') {
            errors.SCity = 'City is required.';
          }
          if (data.SAddress1.trim() === '') {
            errors.SAddress1 = 'Address Line 1 is required.';
          }
        if (!data.SZipcode) {
            errors.SZipcode = 'Zipcode is required.';
          }
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
        setValidationErrors({});
        setsubmitbutton(false);
          if (id) {
            const res = await update_Saddress(data,id);
            console.log(res)
          if (res.status == 200) {
            handleExceptionSuccessMessages(res);
            navigate("/saved_address");   
            }else{
              setsubmitbutton(true);
                handleExceptionError(res.response.data.ErrorMessage)
            }
          }else{
          const res = await add_Saddress(data);
          console.log(res)
          if (res.status == 200) {
            handleExceptionSuccessMessages(res);
            navigate("/saved_address");   
            }else{
              setsubmitbutton(true);
                handleExceptionError(res.response.data.ErrorMessage)
            }
          }
      };
    return(
        <div>
          <Head_CateHead/>
             <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
            <Account_head/>
            <div class="sign_in_wrapper container account">
            <div class="container sign_container">
        <Slide_title title="Add address" className="add_address_page_title"/>
        <div class="sign_up_wrapper">
            <form onSubmit={handleSubmit}>
                <div className="checkout_form_tel">
                    <h3>Customer Name</h3>
                    <input type="text"value={data.SCustomerName}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, SCustomerName: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, SCustomerName: '' }));
                         }}/>
                         {validationErrors.SCustomerName && <div className="error">{validationErrors.SCustomerName}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Country</h3>
                    <input type="text"value={data.SCountry}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, SCountry: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, SCountry: '' }));
                         }}/>
                         {validationErrors.SCountry && <div className="error">{validationErrors.SCountry}</div>}
                </div>
                <div className="group_input"> 
                    <div className="checkout_form_tel">
                        <h3>State</h3>
                        <input type="text"value={data.SState}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, SState: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, SState: '' }));
                         }}/>
                         {validationErrors.SState && <div className="error">{validationErrors.SState}</div>}
                    </div> 
                    <div className="checkout_form_tel">
                        <h3>City</h3>
                        <input type="text"value={data.SCity}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, SCity: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, SCity: '' }));
                         }}/>
                         {validationErrors.SCity && <div className="error">{validationErrors.SCity}</div>}
                    </div> 
                </div>
                <div className="checkout_form_tel">
                    <h3>Zip Code</h3>
                    <input type="text" value={data.SZipcode}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, SZipcode: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, SZipcode: '' }));
                         }}/>
                         {validationErrors.SZipcode && <div className="error">{validationErrors.SZipcode}</div>}
                </div>
                <div className="checkout_form_tel">
                    <h3>Address Line 1</h3>
                    <input type="text"value={data.SAddress1}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, SAddress1: newValue }));
                           setValidationErrors((prevErrors) => ({ ...prevErrors, SAddress1: '' }));
                         }}/>
                         {validationErrors.SAddress1 && <div className="error">{validationErrors.SAddress1}</div>}
                </div> 
                <div className="checkout_form_tel">
                    <h3>Address Line 2</h3>
                    <input type="text"value={data.SAddress2}
                         onChange={(event) => {
                           const newValue = event.target.value;
                           setData((prevData) => ({ ...prevData, SAddress2: newValue }));
                         }}/>
                </div> 
                <br />
                <div class="order_box_delete">
                  {submitbutton?
                    <button style={{backgroundColor:'#01AEF0'}} type="submit">Add</button>
                    :
                    <button style={{backgroundColor:'#01AEF0'}} type="button">Add</button>
                  }
                </div>
                
            </form>
         </div>
      </div>
            </div>
            <Footer />
        </div>
    );
}
