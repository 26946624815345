import React from 'react';

export const Category_box = ({ title, subtitle, imageSrc }) => {
  return(
  <div className="category_box">
    <div className="category_box_img">
      <img src={imageSrc} alt={title} />
    </div>
    <div className="category_box_content">
      <div className="category_box_title">
        <h3>{title}</h3>
      </div>
      {/* <div className="category_box_subtitle">
        <h4>{subtitle}</h4>
      </div> */}
    </div>
  </div>
  );
};