// AboutPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';

import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import t_shirt from "./../assets/img/t-shirt.png";
import { Category_Header } from "./../components/category-header.js";
import { Category_box } from "./../components/category_list_box.js";
import { portal_subcategory } from "../service/api.js";
import Head_CateHead from '../components/Head_CateHead.js';
import Breadcrumb from '../components/Breadcrumb.js';  // Ensure correct import path


export const Portal_list = () => {
  const { slug } = useParams();
  var Portalid = '';
  const navigate = useNavigate();

  const [crumbs, setcrumbs] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [bannerImg, setbannerImg] = useState("");
  const [Img, setImg] = useState("");
  const mainPortalID = Portalid; // Replace with your main PortalID
  const [title, setTitle] = useState('')


  useEffect(() => {
    const fetchSubCategoryList = async () => {
      try {
        // Fetch subcategory data based on the main PortalID
        const response = await portal_subcategory(slug);
        console.log("API Response:", response); // Log the entire API response

        setTitle(response?.data?.Data?.PortalName)
        setbannerImg(response?.data?.Data?.PortalBanner)
        setImg(response?.data?.Data?.PortalImage)
        // Assuming your API response contains an array of subcategories
        const subCategoryData = response.data.Data.SubCategory;
        console.log("Subcategory Data:", subCategoryData); // Log the subcategory data

        const extractedSubCategoryList = subCategoryData.map((subcategoryData) => {
          const subCategory = subcategoryData.SubCategory;
          return {
            id: subCategory.SubCategoryID,
            name: subCategory.SubCategoryName,
            imageSrc: `${process.env.REACT_APP_API_URL + subCategory.SubCategoryImage || process.env.REACT_APP_API_URL + "default.jpg"}`,
            Slug: subCategory.Slug,
          };
        });

        console.log("Extracted Subcategory List:", extractedSubCategoryList); // Log the extracted subcategory list
        setSubCategoryList(extractedSubCategoryList);
      } catch (error) {
        console.error("Error fetching subcategory list", error);
      }
    };
    fetchSubCategoryList();
    var vrcrumbs = [{ label: "Home", path: "/" }];
    vrcrumbs.push({ label: title, path: "" });
    setcrumbs(vrcrumbs);
  }, [slug, title]);

  return (
    <div>
      <Head_CateHead />
      <div className="container_wrapper">

        {bannerImg == null || Img == '' ?
          <div style={{ height: '250px', background: '#3238501a', marginBottom: '40px' }}>
            <h1 style={{ textAlign: 'center', paddingTop: '80px', color: '#01AEF0', fontSize: '3.5vw' }}>{title}</h1>
          </div>
          :
          <img style={{ width: '100%', marginBottom: '40px' }} src={process.env.REACT_APP_API_URL + bannerImg} />
        }
        {/* <Slide_title title={title} className="category_title" /> */}
      </div>
      <div className="category_listing_wrapper">
        <div className="container">
          <Breadcrumb crumbs={crumbs} />
          <br />
          <div className="category_list">
            {subCategoryList.map((subCategory, index) => (
              <Link to={`/${slug}/${subCategory.Slug}`} key={index}>
                <Category_box
                  title={subCategory.name}
                  imageSrc={subCategory.imageSrc}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}