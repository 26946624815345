import React from "react";
import { Link } from 'react-router-dom';
import { Footer } from "../components/Footer.js";
import Head_CateHead from "../components/Head_CateHead.js";
import order_thanks from "./../assets/img/order_thanks.jpg";
import { Widgets } from "@mui/icons-material";

const OrderThanks = () => {
    const orderThanksStyles = {
        textAlign: 'center',
        margin: '50px auto',
        padding: '20px',
        maxWidth: '600px',
        backgroundColor: '#fff',
    };
    const headingStyles = {
        fontSize: '2em',
        marginBottom: '20px',
        color: '#333',
    };
    const paragraphStyles = {
        fontSize: '1.2em',
        color: '#666',
    };
    const footerStyles = {
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#333',
        color: '#fff',
    };
    return (
        <div>
            <Head_CateHead />
            <div style={orderThanksStyles}>
                <h1 style={headingStyles}>Thank You for Your Order!</h1>
                <p style={paragraphStyles}>Your order has been successfully placed. We appreciate your business.</p>
                <img src={order_thanks} style={{width:'60%'}} />
            </div>
            <Footer style={footerStyles} />
        </div>
    );
};

export default OrderThanks;
