import React, { useState, useEffect } from "react";
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "../components/slide-title.js";

import t_shirt from "./../assets/img/t-shirt.png";
import fill from "./../assets/img/fill.svg";
import upload from "./../assets/img/upload.svg";
import disc from "./../assets/img/disc.svg";
import remove from "./../assets/img/remove.svg";
import size from "./../assets/img/size.svg";
import user from "./../assets/img/user.svg";
import account from "./../assets/img/account.png";
import rebox from "./../assets/img/rebox.svg";
import pay from "./../assets/img/pay.svg";
import { Category_Header } from "../components/category-header.js";
import { Account_head } from "../components/account_head.js";
import { basic_info, upload_profile_pic } from "../service/api.js";
import ErrorSnackbar from "../components/ErrorSnackbar.js";
import SuccessSnackbar from "../components/SuccessSnackbar.js";
import { useNavigate } from "react-router-dom";
import profilepic from "../assets/img/profilepic.svg";
import Head_CateHead from "../components/Head_CateHead.js";

export const Account = () => {
  const navigate = useNavigate();

  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [hasProfilePic, setHasProfilePic] = useState(false);
  const [profilePic, setProfilePic] = useState(null);

  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError((ExceptionError) => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  function handleExceptionSuccessMessages(res) {
    setSuccessMessages((successMessages) => [
      ...successMessages,
      { id: Date.now(), message: res.data.Message },
    ]);
  }

  function clearErrors(id) {
    setExceptionError((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }

  const [data, setData] = useState({
    FullName: "",
    Email: "",
    MobileNumber: localStorage.getItem("MobileNumber"),
    DOB: "",
    Gender: "",
    Bio: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    FullName: "",
    Email: "",
    MobileNumber: "",
  });

  const data_get = async () => {
    try {
      setData({
        FullName: localStorage.getItem("FullName"),
        Email: localStorage.getItem("Email"),
        MobileNumber: localStorage.getItem("MobileNumber"),
        DOB:
          localStorage.getItem("DOB") == "null"
            ? ""
            : localStorage.getItem("DOB"),
        Gender:
          localStorage.getItem("Gender") == "null"
            ? ""
            : localStorage.getItem("Gender"),
        Bio:
          localStorage.getItem("Bio") == "null"
            ? ""
            : localStorage.getItem("Bio"),
      });
      const storedProfilePic = localStorage.getItem("ProfilePic");
      if (storedProfilePic) {
        setProfilePic(storedProfilePic);
    
        setHasProfilePic(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      data_get();
    } else {
      navigate("/signin");
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (data.FullName.trim() === "") {
      errors.FullName = "Fullname is required.";
    }
    if (data.Email.trim() === "") {
      errors.Email = "Email is required.";
    }
    if (data.Email) {
      const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (!email_pattern.test(data.Email)) {
        errors.Email = "Invalid Email format";
      }
    }
    if (data.MobileNumber.trim() === "") {
      errors.MobileNumber = "MobileNumber is required.";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});

    const res = await basic_info(data);
    console.log("res " , res)
    if (res.status == 200) {
      localStorage.setItem("DOB", data.DOB);
      localStorage.setItem("Gender", data.Gender);
      localStorage.setItem("Bio", data.Bio);
      localStorage.setItem("MobileNumber", data.MobileNumber);
      localStorage.setItem("FullName", data.FullName);
      handleExceptionSuccessMessages(res);
    } else {
      handleExceptionError(res.response.data.ErrorMessage);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    uploadProfilePic(file);
  };
  const uploadProfilePic = async (ProfilePic) => {
    console.log(ProfilePic)
    try {
      
     
      // Make an API call to upload the profile picture
      const response = await upload_profile_pic(ProfilePic)
      console.log("res upload " , response)
        

      
        // Handle the success scenario, e.g., update the UI, show a success message, etc.
        console.log("Profile picture uploaded successfully");
      console.log(response.ProfilePic[0])
      if (response.ProfilePic ) {
        const filename = response.ProfilePic[0].filename;
          setProfilePic(filename);
          setHasProfilePic(true);
          localStorage.setItem("ProfilePic", filename);

      }
        console.log("")
    
    } catch (error) {
      console.error("Error uploading profile picture:", error.message);
    }
  };
  return (
    <div>
      <ErrorSnackbar
        errorMessages={ExceptionError}
        onClearErrors={clearErrors}
      />
      <SuccessSnackbar
        successMessages={successMessages}
        onclearSuccess={clearSuccess}
      />
      <Head_CateHead />
      <Account_head title_="Account infomation" />
      <div className="account_detail container account">
        <div className="account_detail_left">
          <input
            type="file"
            name="profile"
            id="profile"
            className="file_input"
            onChange={handleFileChange}
          />
          <span>
            <img src={profilepic} alt="profilepic" />
          </span>
          {
            hasProfilePic ? (
                <img src={`${process.env.REACT_APP_API_URL}/${profilePic}`} alt="profile pic" height={154} width={154} className="profile" />
            ):(
                <img src={account}
           alt="t-shirt" />
            )
          }
          
          
        </div>
        <div className="account_detail_right">
          <form onSubmit={handleSubmit}>
            <div className="checkout_form_tel account first">
              <h3>Fullname</h3>
              <input
                type="text"
                value={data.FullName}
                onChange={(event) => {
                  const newValue = event.target.value;
                  setData((prevData) => ({ ...prevData, FullName: newValue }));
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    FullName: "",
                  }));
                }}
              />
              {validationErrors.FullName && (
                <div className="error">{validationErrors.FullName}</div>
              )}
            </div>
            <div className="checkout_form_tel account">
              <h3>Email address</h3>
              <input
                type="mail"
                value={data.Email}
                onChange={(event) => {
                  const newValue = event.target.value;

                  setData((prevData) => ({ ...prevData, Email: newValue }));
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    Email: "",
                  }));
                }}
              />
              {validationErrors.Email && (
                <div className="error">{validationErrors.Email}</div>
              )}
            </div>
            <div className="checkout_form_tel account">
              <h3>Phone number</h3>
              <input
                type="tel"
                value={data.MobileNumber}
                onChange={(event) => {
                  const newValue = event.target.value;
                  setData((prevData) => ({
                    ...prevData,
                    MobileNumber: newValue,
                  }));
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    MobileNumber: "",
                  }));
                }}
              />
              {validationErrors.MobileNumber && (
                <div className="error">{validationErrors.MobileNumber}</div>
              )}
            </div>
            <div className="one_liner">
              <div className="checkout_form_tel account">
                <h3>Date</h3>
                <input
                  type="date"
                  value={data.DOB}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setData((prevData) => ({ ...prevData, DOB: newValue }));
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      DOB: "",
                    }));
                  }}
                />
              </div>
              <div className="checkout_form_tel account gender">
                <h3>Gender</h3>
                <select
                  value={data.Gender}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setData((prevData) => ({ ...prevData, Gender: newValue }));
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      Gender: "",
                    }));
                  }}
                >
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
            </div>
            <div className="checkout_form_tel account area">
              <h3>About you</h3>
              <textarea
                rows="6"
                value={data.Bio}
                onChange={(event) => {
                  const newValue = event.target.value;
                  setData((prevData) => ({ ...prevData, Bio: newValue }));
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    Bio: "",
                  }));
                }}
              ></textarea>
            </div>
            <button type="submit">Update account</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
