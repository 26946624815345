import React,{useEffect} from 'react'
import { Header } from './Header'
import { Category_Header } from './category-header'

const Head_CateHead = () => {

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500); // 2000 milliseconds (2 seconds)
    
        // Clear the timeout if the component unmounts or the effect runs again
        return () => clearTimeout(timeoutId);
    }, []);
    
    
  return (
      <div className="nav_bar">
          <div>
              <Header />
              <div>
                  <Category_Header />
              </div>
          </div>
      </div>
  )
}

export default Head_CateHead