import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ErrorSnackbar({ errorMessages, onClearErrors }) {
  
  useEffect(() => {
    if (errorMessages.length === 0) return; 

    errorMessages.forEach(errorMessage => {
      const toastId = toast.error(errorMessage.message, {
        autoClose: false, 
        className: 'custom-toast', 
        closeButton: false, 
        progressClassName: 'red-progress', 
        toastId: errorMessage.id, 
      });

      setTimeout(() => {
        onClearErrors(errorMessage.id);
        toast.dismiss(toastId); 
      }, 3000); 
    });
  }, [errorMessages, onClearErrors]); 

  
  if (errorMessages.length === 0) return null;

  return <ToastContainer position="bottom-right" />;
}

export default ErrorSnackbar;
