// AboutPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import t_shirt from "./../assets/img/t-shirt.png";
import { Category_Header } from "./../components/category-header.js";
import { Category_box } from "./../components/category_list_box.js";
import { Category_listing, sub_category_listing } from "../service/api.js";
import Head_CateHead from "../components/Head_CateHead.js";

export const Category = () => {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Category_listing();
        console.log("response category" , response)
        const categoriesData = response.data.Data; // Access the Data array

        const extractedCategories = categoriesData.map((categoryData) => {
          return {
              title: categoryData.CategoryName || "Default Title",
              count: categoryData.IsMenu, // You need to define how to get the count
              imageSrc: categoryData.CategoryImage || "default.jpg", // Use the image URL directly
              Slug: categoryData.Slug, // Use the image URL directly
          };
        });

        setCategories(extractedCategories);
        console.log("response data", extractedCategories);
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div>
      <Head_CateHead />
      <div className="container">
        <Slide_title title="Shop by category" className="category_title" />
      </div>
      <div className="category_listing_wrapper">
        <div className="container">
          <div className="category_list">
            {categories.map((category, index) => (
              <Link to={`/category/${category.Slug}`} key={index} >
                <Category_box
                  title={category.title}
                  subtitle={`${category.count}+ categories`}
                  imageSrc={process.env.REACT_APP_API_URL + category.imageSrc}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
