import React from 'react';
import './../App.css';
import Box from '@mui/material/Box';
import { display, alignItems } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';



export const Slide_title = ({title, className}) => {
    return (
        <div>
            <div className='slide-title'>
                <h2 className={className}>{title}
                {/* <img src="../arr.svg" className="title_svg"/> */}
                </h2>
                
            </div>
        </div>
    );
}
