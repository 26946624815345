import React from "react";
import "./../App.css";
import logo from "./../assets/img/Logo.png";
import facebook from "./../assets/img/facebook.svg";
import mail from "./../assets/img/mail.svg";
import heart from "./../assets/img/heart.svg";
import insta from "./../assets/img/insta.svg";
import Box from "@mui/material/Box";
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div className="footer-wrapper">
      <hr />
      <div className="container">
        <Box
          component="div"
          sx={{ display: "flex", alignItems: "top" }}
          className="footer"
        >
          <div className="footer-bebrand-detail">
            <div className="footer-logo">
            <Link to="https://bebranded.com.au/"><img src={logo} /></Link>
            </div>
            <div className="footer-detail">
              <p>
                Be Branded is a family owned and operated business,
                <br />
                beginning our path in 2007 Be Branded has established it’s
                <br />  
                self as a true market leader.
              </p>
            </div>
          </div>
          <div className="footer-our-company">
            <h2>Our company</h2>
            <ul>
              <Link to="/terms_condition"><li>Terms & Condition</li></Link>
              {/* <Link to=""><li>Delivery & Returns</li></Link> */}
              {/* <Link to="/products"><li>All Products</li></Link> */}
              {/* <Link to="/contact"><li>Contact & Support</li></Link> */}
              <Link to="/faq"><li>FAQs</li></Link>
            </ul>
          </div>
          <div className="footer-contact">
          <h2>Contact us</h2>
            <ul>
              <Link to="tel:1300115733"><li>1300 11 57 33</li></Link>
              <Link to="mailto:info@bebranded.com.au"><li>info@bebranded.com.au</li></Link>
            </ul>
          </div>
          <div className="footer-follow">
            <h2>Follow us</h2>

            <div className="footer-icons">
            <div className="footer-icon">
            <Link to="https://www.facebook.com/BebrandedPromo" target="_blank"><img src={facebook}/></Link>
            </div>
            <div className="footer-icon">
            <Link to="mailto:info@bebranded.com.au" target="_blank"><img src={mail}/></Link>
            </div>
            <div className="footer-icon">
            <Link to="https://www.instagram.com/_bebranded/" target="_blank"><img src={insta}/></Link>
            </div>
            </div>
            </div>
        </Box>
      </div>
      <hr />
      <div className="container">
        <Box
          component="div"
          sx={{ display: "flex", alignItems: "top", justifyContent:"space-between" }}
          className="bottom_footer"
        >
            <p>Copyright {new Date().getFullYear()} © Be Branded. All Rights Reserved.</p>
            <Link to="https://www.resolutesolutions.in/"><p>Made with <img src={heart}/> from Resolute Solutions.</p></Link>
        </Box>
      </div>
    </div>
  );
};
