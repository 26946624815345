import React, {useEffect, useState} from 'react';
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import t_shirt from "./../assets/img/t-shirt.png";
import { Account_head } from "./../components/account_head.js";
import { get_wishlist,del_wishlist } from "../service/api.js";
import { Link, useNavigate } from "react-router-dom";
import Head_CateHead from '../components/Head_CateHead.js';
import Alert from '../components/Alert.js';

export const Acc_wish_list = () => {
    const[showConfirmBox , setShowConfirmBox] = useState(false)
  const[removeId,setRemoveId] = useState("")
    const navigate = useNavigate();
    const [data, setdata] = useState([]);
    const data_get = async () => {

        try {
          const res = await get_wishlist(); 
          console.log(res.data.Data);
          setdata(res.data.Data);
          console.log("data" , data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const removeFromWishlist = async (productId) => {
        console.log("open please");
        setShowConfirmBox(true);  
        setRemoveId(productId)
    
    }
    useEffect(() => {
        if(localStorage.getItem('token')){
            data_get();
        }else{
            navigate("/signin");   
        }
    }, []);
    console.log(data);

    const handleRemoveFromWishlist = async (productId)=> {
        try {
            
      console.log("productId:---",productId);
            // Send a DELETE request to remove the item from the wishlist
            await del_wishlist(productId);
      
            // Update the data state by removing the deleted item
            setdata((prevData) => prevData.filter((item) => item.ProductID !== productId));
            setShowConfirmBox(false)
          } catch (error) {
            console.error("Error removing item from wishlist:", error);
          }

    }
    
    return (
        <div>
        {showConfirmBox && <Alert message="Are you sure you want to remove this item from the cart?" showConfirmBox={showConfirmBox} setShowConfirmBox={setShowConfirmBox} onConfirm={handleRemoveFromWishlist} id={removeId} />}
            <Head_CateHead/>
            <Account_head title_="Wishlist" />
            <div class="sign_in_wrapper container account">
                <div className='order_box_list'>
                   {data.map((item, index) => (

                    <div className='order_box'>
                        <div className='order_box_img_content'>
                            <div className='order_box_img'>
                                   <img src={
                                       process.env.REACT_APP_API_URL+item.ProductImage ||
                                       process.env.REACT_APP_API_URL+"default.jpg"
                                   } />
                            </div>
                            <Link to={`/product/${item.Slug}`} className='order_box_title'>
                                <h3>{item.ProductName}</h3>    
                            </Link>
                        </div>
                        <div className='order_box_content'>
                            <div className='order_box_delete'>
                            <button onClick={() => removeFromWishlist(item.ProductID)}>Remove</button>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}