import React, { useState } from 'react';
//import heart_fill from "./../assets/img/heart.svg";
import filledHeartImage from "./../assets/img/heart.svg";
import unfilledHeartImage from "./../assets/img/heartunfilled.svg";

export const ProductBox = ({ ProductID, UserProductWishlist,heart_fill, title, subTitle, image, colors, onHeartClick }) => {
  // Check if UserProductWishlist is available and not empty
 

  // Assign the value of isProductInWishlist to heart_fill
  //const heart_fill = isProductInWishlist;
  const isAuthenticated = !!localStorage.getItem("token"); 

    // const [activeColorIndex, setActiveColorIndex] = useState(0);
  
  // const handleClick = (index) => {
  //   setActiveColorIndex(index);
  // };

  
    return (
      <div className="arrivals_box">
        <div className="arrivals_img">
          <div className="product_head">
            {/* <div className='product-tag'>
                <img src={tag_img}/><h5>{tags}</h5>
            </div> */} 
          </div>
          <img src={process.env.REACT_APP_API_URL+image} alt={title} className="pro_hero_img" />
        </div>
        <div className="arrivals_content">
          {/* <div className="arrivals_color_list">
          {colors.map((color, index) => (
            <div className={`arrivals_color_box ${index === activeColorIndex ? 'active' : ''}`}
            key={index}
            onClick={() => handleClick(index)}>
              <div className="arrivals_color" style={{ backgroundColor: color }}></div>
            </div>
          ))}
        </div> */}
          <div className="arrivals_content_title">
            <h3>{title}</h3>
            {/* <h4>{subTitle}</h4> */}
          </div>
        </div>
      </div>
    );
};
