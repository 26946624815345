import React, { useState, useEffect, useRef } from "react";
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "../components/slide-title.js";
import t_shirt from "./../assets/img/t-shirt.png";
import { Link } from "react-router-dom";
import fill from "./../assets/img/fill.svg";
import upload from "./../assets/img/upload.svg";
import disc from "./../assets/img/disc.svg";
import remove from "./../assets/img/remove.svg";
import size from "./../assets/img/size.svg";
import user from "./../assets/img/user.svg";
import rebox from "./../assets/img/rebox.svg";
import pay from "./../assets/img/pay.svg";
import { Category_Header } from "../components/category-header.js";
import {
  addtocart_get_api,
  checkout_billpost_api,
  checkout_shippost_api,
  checkoutbilladdress2_api_call,
  checkout_shipget_list_api,
  checkout_billget_list_api,
  place_order_api,
  gstdata_get_api,
  get_coupon_value_by_name,
  get_coupon_value_by_name_again,
  coupen_by_name_again,
} from "../service/api.js";
import Validation from "../components/validation.js";
import ErrorSnackbar from "../components/ErrorSnackbar.js";
import { useNavigate } from "react-router-dom";
import SuccessSnackbar from "../components/SuccessSnackbar.js";
import Head_CateHead from "../components/Head_CateHead.js";

export const Checkout = () => {
  const navigate = useNavigate();
  const [api_data, setapi_data] = useState([]);
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [showBillingAddress, setShowBillingAddress] = useState(false);

  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState({});
  const [selectedBillingAddress, setSelectedBillingAddress] = useState({});

  const [orderTotal, setOrderTotal] = useState(0);
  const [blanckKey, setBlanckKey] = useState([]);
  const [couponName, setcouponName] = useState("");
  const [ponumber, setponumber] = useState("");
  const [ponumber_err, setponumber_err] = useState("");
  const [getedCouponValue, setGetedCouponValue] = useState(0);

  useEffect(() => {
    const subtotal = api_data?.reduce(
      (total, item) => total + parseFloat(calculateSubtotal(item)),
      0
    );
    const gstAmount = parseFloat(calculateGST(subtotal));
    const totalAmount = subtotal + gstAmount;
    setOrderTotal(totalAmount);
  }, [api_data]);

  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError((ExceptionError) => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }
  function handleExceptionSuccessMessages(res) {
    if (res.Message) {
      setSuccessMessages((successMessages) => [
        ...successMessages,
        { id: Date.now(), message: res.Message },
      ]);
    }
  }
  function clearErrors(id) {
    setExceptionError((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cartApiData = await addtocart_get_api();
        // Ensure that cartApiData.Data is an array before setting the state
        if (cartApiData?.Data?.length === 0) {
          navigate("/");
        } else {
          setapi_data(cartApiData.Data);
        }
        // Fetch the GST rate
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };

      if (localStorage.getItem("token")) {
        fetchGST();
        fetchData();
      } else {
        navigate("/signin");
      }
  }, []);

  // Function to handle unavailable images
  const getImageUrl = (item) => {
    return (
      process.env.REACT_APP_API_URL + item.ProductImage ||
      process.env.REACT_APP_API_URL + "default.jpg"
    );
  };
  // Calculate subtotal
  const calculateSubtotal = (item) => {
    const qty = item.Carts[0].QTY;
    const basePrice = parseFloat(item.Carts[0].BasePrice);
    const subtotal = parseFloat(qty * basePrice);
    return subtotal.toFixed(2);
  };

  const [gstRate, setGSTRate] = useState(""); // Default value

  const fetchGST = async () => {
    try {
      const GSTData = await gstdata_get_api();
      // Set the fetched GST rate to a state variable
      setGSTRate(Number(GSTData.Data.Rate));
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };
  // Calculate GST
  const calculateGST = (subtotal) => {
    if (gstRate) {
      const gstTotal = subtotal * (gstRate / 100);
      return gstTotal.toFixed(2);
    } else {
      return 0;
    }
  };

  const [values, setValues] = useState({
    SCustomerName: "",
    SCountry: "",
    SState: "",
    SCity: "",
    SZipcode: "",
    SAddress1: "",
    SAddress2: "",
    BEmail: "",
    BMobileNumber: "",
    BCustomerName: "",
    BCountry: "",
    BState: "",
    BCity: "",
    BAddress1: "",
    BAddress2: "",
    BZipcode: "",
  });
  const [mail, setmail] = useState(false);

  const [zipcodeError, setZipCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [szipcodeError, setsZipCodeError] = useState("");
  const [zipcode, setZipCode] = useState(false);
  const [szipcode, setsZipCode] = useState(false);

  useEffect(() => {
    console.log(mail);
  }, [mail]);
  function handleInput(event) {
    let tempValue = { ...values };
    if (event.target.name === "BEmail") {
      // alert(5);
      // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      const isValidEmail = emailPattern.test(event.target.value);
      console.log(isValidEmail);
      if (event.target.value.trim() == "") {
        setEmailError("Email is required");
      } else if (isValidEmail) {
        setEmailError("");
        setmail(false);
      } else {
        setEmailError("Email is invalid");
        setmail(true);
      }
    }

    if (event.target.name === "BZipcode") {
      // const validZipTest = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      const validZipTest = /^\d{4,6}$/;
      const isValidZipCode = validZipTest.test(event.target.value);

      if (event.target.value.trim() == "") {
        setZipCodeError("Zipcode is required");
        setZipCode(true);
      }
      // else if (isValidZipCode) {
      // }
      else {
        setZipCodeError("");
        setZipCode(false);
        // setZipCodeError("Zipcode is invalid");
        // setZipCode(true);
      }
    }
    if (event.target.name === "SZipcode") {
      // const validsZipTest = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      const validsZipTest = /^\d{4,6}$/;
      const isValidsZipCode = validsZipTest.test(event.target.value);

      if (event.target.value.trim() == "") {
        setsZipCodeError("Zipcode is required");
        setsZipCode(true);
      } else if (!isValidsZipCode) {
        setsZipCodeError("");
        setsZipCode(false);
        // } else if (event.target.value.length > 4 || !isValidsZipCode) {
      }
    }
    // alert("ds");
    const newObj = { ...tempValue, [event.target.name]: event.target.value };
    setBlanckKey(blanckKey?.filter((item) => item !== event.target.name));
    setValues(newObj);
  }

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const billingResponse = await checkout_billget_list_api();
      const billingAddresses = billingResponse.Data;
      const lastBAddress = billingAddresses[billingAddresses?.length - 1];

      const shippingResponse = await checkout_shipget_list_api();
      const shippingAddresses = shippingResponse.Data;
      const lastSAddress = shippingAddresses[shippingAddresses?.length - 1];

      setValues((prevValues) => ({
        ...prevValues,
        BCustomerName: lastBAddress?.BCustomerName || "",
        BCountry: lastBAddress?.BCountry || "",
        BState: lastBAddress?.BState || "",
        BCity: lastBAddress?.BCity || "",
        BAddress1: lastBAddress?.BAddress1 || "",
        BAddress2: lastBAddress?.BAddress2 || "",
        BZipcode: lastBAddress?.BZipcode || "",
        BEmail: lastBAddress?.BEmail || "",
        BMobileNumber: lastBAddress?.BMobileNumber || "",
        SCustomerName: lastSAddress?.SCustomerName || "",
        SCountry: lastSAddress?.SCountry || "",
        SState: lastSAddress?.SState || "",
        SCity: lastSAddress?.SCity || "",
        SAddress1: lastSAddress?.SAddress1 || "",
        SAddress2: lastSAddress?.SAddress2 || "",
        SZipcode: lastSAddress?.SZipcode || "",
      }));

      setBillingAddresses(billingAddresses);
      setShippingAddresses(shippingAddresses);
    } catch (error) {
      console.error("API call error:", error);
      // Handle error if needed
    }
  };

  const [Subtotal, setSubtotal] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  useEffect(() => {
    let priceArray = [];
    if (api_data) {
      api_data?.map((product) =>
        product?.Carts?.map((cart) => {
          priceArray.push(Number(cart?.BasePrice * cart?.QTY));
        })
      );
    }
    const CalSubtotal = parseFloat(
      priceArray?.reduce((sum, item) => sum + item, 0)
    ).toFixed(2);
    if (CalSubtotal) {
      setSubtotal(CalSubtotal);
      // if (CalSubtotal && getedCouponValue) {
      //     const withCoupon = parseFloat(CalSubtotal - getedCouponValue * CalSubtotal / 100)
      //     setSubtotal(withCoupon)
      // }
    }
    if (gstRate) {
      const withGst = parseFloat(((CalSubtotal * gstRate) / 100).toFixed(2));
      setGstAmount(withGst);
      // if (gstRate && getedCouponValue) {
      //     const withCoupon = parseFloat((CalSubtotal - (getedCouponValue * CalSubtotal / 100)) * gstRate / 100)
      //     setGstAmount(withCoupon)
      // }
    }
  }, [api_data, getedCouponValue, gstRate]);
  const priceAfterDiscount = (Subtotal * getedCouponValue) / 100;
  const gstWithCoupen = ((Subtotal - priceAfterDiscount) * gstRate) / 100;

  const handleplaceorder = async () => {
    let value = { ...values };
    // console
    const emptyKey = Object.keys({ ...values }).filter(
      (key) =>
        key !== "BAddress2" &&
        key !== "SAddress2" &&
        (values[key].trim() === "" || /^\s+$/.test(values[key]))
    );
    console.log(emptyKey.length);
    console.log(showBillingAddress);
    if (emptyKey.length > 0) {
      if (values.BZipcode.trim() === "") {
        setZipCodeError("Zipcode is required");
        setZipCode(true);
      }
      if (values.BEmail.trim() === "") {
        setEmailError("Email is required");
        setmail(true);
      }
      if (values.SZipcode.trim() === "") {
        setsZipCodeError("Zipcode is required");
        setsZipCode(true);
      }
      if (!showBillingAddress) {
        if (emptyKey.length > 0) {
          setBlanckKey(emptyKey);
        }

        // return
      } else if (emptyKey.length > 0 || emailError || zipcodeError) {
        if (values.SZipcode.trim() === "") {
          setsZipCodeError("Zipcode is required");
          setsZipCode(true);
        }
        setBlanckKey(emptyKey);
        return;
      }
    }

    if (ponumber == "") {
      setponumber_err("PO# or Order reference number is required");
      return;
    }

    if (!showBillingAddress) {
      console.log(values);
      let tempValues = { ...value };
      tempValues.SCustomerName = value.BCustomerName;
      tempValues.SCountry = value.BCountry;
      tempValues.SState = value.BState;
      tempValues.SCity = value.BCity;
      tempValues.SZipcode = value.BZipcode;
      tempValues.SAddress1 = value.BAddress1;
      tempValues.SAddress2 = value.BAddress2;
      value = { ...tempValues };
    }

    const data = {
      ...value,
      Totalprice: (parseFloat(Subtotal) + parseFloat(gstAmount)).toFixed(2),
      PaymentMethod: "Credit Card",
      IpAddress: "192.168.1.1",
      SpliteOrder: false,
      GSTAmount: gstAmount,
      GSTText: gstRate,
      CouponName: couponName,
      CouponValue: getedCouponValue,
      ponumber: ponumber,
    };

    try {
      if (mail === false && zipcode === false) {
        console.log("data", { ...data });

        const res = await place_order_api({ ...data });
        console.log("place api res ", res);
        if (res.status === 200) {
          localStorage.removeItem("couponName");
          setTimeout(() => {
            navigate("/thanks_order");
          }, 1000);
        }
      }
    } catch (error) {}
  };

  const [showShippingAddress, setShowShippingAddress] = useState(true);

  const handleShippingAddressChange = (index) => {
    setSelectedShippingAddress(shippingAddresses[index]);
    const seleShipAdd = { ...shippingAddresses[index] };
    let tempValues = { ...values };
    tempValues.SCustomerName = seleShipAdd.SCustomerName;
    tempValues.SCountry = seleShipAdd.SCountry;
    tempValues.SState = seleShipAdd.SState;
    tempValues.SCity = seleShipAdd.SCity;
    tempValues.SZipcode = seleShipAdd.SZipcode;
    tempValues.SAddress1 = seleShipAdd.SAddress1;
    tempValues.SAddress2 = seleShipAdd.SAddress2;
    setValues({ ...tempValues });
    setShowShippingAddress(false);
  };

  const handleAddressChangeButton = () => {
    setShowShippingAddress(true); // Show the address form again
    setSelectedShippingAddress(null); // Reset the selected address
    setShowBillingAddress(true);
  };

  const [showBillAddress, setShowBillAddress] = useState(true);

  const handleBillingAddressChange = (index) => {
    setSelectedBillingAddress(billingAddresses[index]);
    const seleBillAdd = { ...billingAddresses[index] };
    let tempValues = { ...values };
    tempValues.BCustomerName = seleBillAdd.BCustomerName;
    tempValues.BCountry = seleBillAdd.BCountry;
    tempValues.BState = seleBillAdd.BState;
    tempValues.BCity = seleBillAdd.BCity;
    tempValues.BZipcode = seleBillAdd.BZipcode;
    tempValues.BAddress1 = seleBillAdd.BAddress1;
    tempValues.BAddress2 = seleBillAdd.BAddress2;
    tempValues.BMobileNumber = seleBillAdd.BMobileNumber;
    tempValues.BEmail = seleBillAdd.BEmail;
    setValues({ ...tempValues });
    setShowBillAddress(false);
  };
  const handlebillAddressChangeButton = () => {
    setShowBillAddress(true); // Show the address form again
    setSelectedBillingAddress(null); // Reset the selected address
  };
  const [errors, setErrors] = useState({});
  // function handleValidation(event) {
  //   event.preventDefault();
  //   const validationErrors = Validation(values); // Pass the values object directly
  //   setErrors(validationErrors);
  // }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/signin");
    }
  }, [errors]);
  const [couponName1, setcouponName1] = useState();

  const applyCoupon = async (data) => {
    if (couponName) {
      try {
        const res = await get_coupon_value_by_name({ CouponName: couponName });
        if (res.status === true) {
          setGetedCouponValue(res.value);
          console.log("res , coupen", res);
          setcouponName1(couponName);
          localStorage.setItem("couponName", couponName);
        }
      } catch (error) {
        console.log("err ", error);
      }
    } else {
      try {
        const res = await get_coupon_value_by_name({ CouponName: data });
        if (res.status === true) {
          setGetedCouponValue(res.value);
          console.log(res);
          setcouponName1(data);
          localStorage.setItem("couponName", data);
        }
      } catch (error) {}
    }
  };
  const applyonRefresh = async (data) => {
    if (couponName) {
      try {
        const res = await coupen_by_name_again({ CouponName: couponName });
        if (res.status === true) {
          setGetedCouponValue(res.value);
          console.log("res , coupen", res);
          setcouponName1(couponName);
          localStorage.setItem("couponName", couponName);
        }
      } catch (error) {
        console.log("err ", error);
      }
    } else {
      try {
        const res = await coupen_by_name_again({ CouponName: data });
        if (res.status === true) {
          setGetedCouponValue(res.value);
          console.log(res);
          setcouponName1(data);
          localStorage.setItem("couponName", data);
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    if (localStorage.getItem("couponName")) {
      applyonRefresh(localStorage.getItem("couponName"));
    }
  }, [localStorage.getItem("couponName")]);
  return (
    <div>
      <ErrorSnackbar
        errorMessages={ExceptionError}
        onClearErrors={clearErrors}
      />
      {/* <SuccessSnackbar
                successMessages={successMessages}
                onclearSuccess={clearSuccess}
            /> */}
      <Head_CateHead />
      <div className="container">
        <Slide_title title="Checkout" className="category_title checkout" />
        <div className="checkout_wrapper">
          <div className="checkout_detail_form mb-4">
            <div className="checkout_contact_form">
              <div className="checkout_contact_head">
                <div className="chekout_contact_icon">
                  <img src={user} />
                </div>
                <div className="chekout_contact_title">
                  <h3>ADD NEW ADDRESS</h3>
                </div>
              </div>
              <hr className="form_head" />
              <div className="checkout_contact_body mb-4">
                <div className="checkout_contact_body_title">
                  <h3>Billing Address</h3>
                  {/* <h4>Do not have an account? <Link to="/signin">Log in</Link></h4> */}
                </div>
                <form
                // onSubmit={handleSubmit}
                >
                  <div className="checkout_form_tel">
                    <h3>Customer Name</h3>

                    <input
                      type="text"
                      name="BCustomerName"
                      id="CustomerName"
                      value={values.BCustomerName}
                      onChange={handleInput}
                    />
                    {blanckKey.some((key) => key === "BCustomerName") && (
                      <p className="giv_error">Customer Name is required</p>
                    )}
                  </div>
                  <div className="checkout_form_tel">
                    <h3>Country</h3>
                    <input
                      type="text"
                      name="BCountry"
                      id="Country"
                      value={values.BCountry}
                      onChange={handleInput}
                    />
                    {blanckKey.some((key) => key === "BCountry") && (
                      <p className="giv_error">Country is required</p>
                    )}
                  </div>
                  <div className="checkout_form_tel">
                    <h3>State</h3>

                    <input
                      type="text"
                      name="BState"
                      id="State"
                      value={values.BState}
                      onChange={handleInput}
                    />
                    {blanckKey.some((key) => key === "BState") && (
                      <p className="giv_error">State is required</p>
                    )}
                  </div>
                  <div className="checkout_form_tel">
                    <h3>City</h3>
                    <input
                      type="tel"
                      name="BCity"
                      id="City"
                      value={values.BCity}
                      onChange={handleInput}
                    />
                    {blanckKey.some((key) => key === "BCity") && (
                      <p className="giv_error">City is required</p>
                    )}
                  </div>
                  <div className="checkout_form_tel">
                    <h3>Zipcode</h3>
                    <input
                      type="tel"
                      name="BZipcode"
                      id="Zipcode"
                      value={values.BZipcode}
                      onChange={handleInput}
                    />

                    {zipcodeError && (
                      <p className="giv_error">{zipcodeError}</p>
                    )}
                  </div>
                  <div className="checkout_form_tel">
                    <h3>Address1</h3>
                    <input
                      type="tel"
                      name="BAddress1"
                      id="Address1"
                      value={values.BAddress1}
                      onChange={handleInput}
                    />
                    {blanckKey.some((key) => key === "BAddress1") && (
                      <p className="giv_error">Address 1 is required</p>
                    )}
                  </div>
                  <div className="checkout_form_tel">
                    <h3>Address2</h3>
                    <input
                      type="text"
                      name="BAddress2"
                      id="Address2"
                      value={values.BAddress2}
                      onChange={handleInput}
                    />
                  </div>

                  <div className="checkout_form_tel">
                    <h3>Email</h3>
                    <input
                      type="email"
                      name="BEmail"
                      id="BillEmail"
                      value={values.BEmail}
                      onChange={handleInput}
                    />

                    {emailError && <p className="giv_error">{emailError}</p>}
                  </div>
                  <div className="checkout_form_tel mb-2">
                    <h3>Mobile Number</h3>
                    <input
                      type="text"
                      name="BMobileNumber"
                      id="BillMobileNumber"
                      value={values.BMobileNumber}
                      onChange={handleInput}
                    />
                    {blanckKey.some((key) => key === "BMobileNumber") && (
                      <p className="giv_error">Mobile Number is required</p>
                    )}
                  </div>

                  <div
                    className="checkbox_mail mt-2"
                    htmlFor="same_shipping_billing_address"
                  >
                    <input
                      id="same_shipping_billing_address"
                      type="checkbox"
                      checked={showBillingAddress}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setShowBillingAddress((prevState) => !prevState);
                          setSelectedShippingAddress({});
                          setShowShippingAddress(false);
                          let tempValues = { ...values };
                          tempValues.SCustomerName = values.BCustomerName;
                          tempValues.SCountry = values.BCountry;
                          tempValues.SState = values.BState;
                          tempValues.SCity = values.BCity;
                          tempValues.SZipcode = values.BZipcode;
                          tempValues.SAddress1 = values.BAddress1;
                          tempValues.SAddress2 = values.BAddress2;
                          setValues({ ...tempValues });
                        } else {
                          setShowBillingAddress((prevState) => !prevState);
                          setShowShippingAddress(true);
                          setSelectedShippingAddress({});
                          let tempValues = { ...values };
                          tempValues.SCustomerName = values.BCustomerName;
                          tempValues.SCountry = values.BCountry;
                          tempValues.SState = values.BState;
                          tempValues.SCity = values.BCity;
                          tempValues.SZipcode = values.BZipcode;
                          tempValues.SAddress1 = values.BAddress1;
                          tempValues.SAddress2 = values.BAddress2;
                          setValues({ ...tempValues });
                        }
                      }}
                    />
                    <span
                      className="ml-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const checkbox = document.getElementById(
                          "same_shipping_billing_address"
                        );
                        if (checkbox) {
                          if (checkbox.checked) {
                            setShowBillingAddress((prevState) => !prevState);
                            let tempValues = { ...values };
                            tempValues.SCustomerName = values.BCustomerName;
                            tempValues.SCountry = values.BCountry;
                            tempValues.SState = values.BState;
                            tempValues.SCity = values.BCity;
                            tempValues.SZipcode = values.BZipcode;
                            tempValues.SAddress1 = values.BAddress1;
                            tempValues.SAddress2 = values.BAddress2;
                            setValues({ ...tempValues });
                          } else {
                            setShowBillingAddress((prevState) => !prevState);
                            console.log("i amhdgvhijdm,");
                            // let tempValues = { ...values };
                            // tempValues.SCustomerName = values.BCustomerName;
                            // tempValues.SCountry = values.BCountry;
                            // tempValues.SState = values.BState;
                            // tempValues.SCity = values.BCity;
                            // tempValues.SZipcode = values.BZipcode;
                            // tempValues.SAddress1 = values.BAddress1;
                            // tempValues.SAddress2 = values.BAddress2;
                            // setValues({ ...tempValues });
                          }
                        }
                      }}
                    >
                      I Have a Different Shipping Address
                    </span>
                  </div>
                  {/*------------------- billing address start from here -------------*/}
                  {showBillingAddress && (
                    <div className="billing_address">
                      <div className="checkout_form_tel">
                        <h3>Customer Name</h3>

                        <input
                          type="text"
                          name="SCustomerName"
                          // id="BCustomerName"
                          value={values.SCustomerName}
                          onChange={handleInput}
                        />
                        {blanckKey.some((key) => key === "SCustomerName") && (
                          <p className="giv_error">Customer Name is required</p>
                        )}
                      </div>
                      <div className="checkout_form_tel">
                        <h3>Country</h3>
                        <input
                          type="text"
                          name="SCountry"
                          // id="BCountry"
                          value={values.SCountry}
                          onChange={handleInput}
                        />
                        {blanckKey.some((key) => key === "SCountry") && (
                          <p className="giv_error">Country is required</p>
                        )}
                      </div>
                      <div className="checkout_form_tel">
                        <h3>State</h3>

                        <input
                          type="text"
                          name="SState"
                          // id="BState"
                          value={values.SState}
                          onChange={handleInput}
                        />
                        {blanckKey.some((key) => key === "SState") && (
                          <p className="giv_error">State is required</p>
                        )}
                      </div>
                      <div className="checkout_form_tel">
                        <h3>City</h3>
                        <input
                          type="tel"
                          name="SCity"
                          // id="BCity"
                          value={values.SCity}
                          onChange={handleInput}
                        />
                        {blanckKey.some((key) => key === "SCity") && (
                          <p className="giv_error">City is required</p>
                        )}
                      </div>
                      <div className="checkout_form_tel">
                        <h3>Address1</h3>
                        <input
                          type="tel"
                          name="SAddress1"
                          // id="BAddress1"
                          value={values.SAddress1}
                          onChange={handleInput}
                        />
                        {blanckKey.some((key) => key === "SAddress1") && (
                          <p className="giv_error">Address1 is required</p>
                        )}
                      </div>
                      <div className="checkout_form_tel">
                        <h3>Address2</h3>
                        <input
                          type="text"
                          name="SAddress2"
                          // id="BAddress2"
                          value={values.SAddress2}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="checkout_form_tel">
                        <h3>Zipcode</h3>
                        <input
                          type="tel"
                          name="SZipcode"
                          // id="BBillEmail"
                          value={values.SZipcode}
                          onChange={handleInput}
                        />

                        {szipcodeError && (
                          <p className="giv_error">{szipcodeError}</p>
                        )}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
            {billingAddresses.length > 0 && (
              <div className="billing_address_detail">
                <div className="chekout_contact_title">
                  <h3>Billing Address</h3>
                </div>
                <div className="checkout_shipping_form">
                  <div className="checkout_contact_head">
                    <div className="chekout_contact_icon">
                      <img src={rebox} />
                    </div>
                    <div className="space-between-checkout">
                      <div className="chekout_contact_title">
                        <h3>Contact info</h3>
                        {selectedBillingAddress && (
                          <div className="selected_address">
                            <p>{selectedBillingAddress.BCustomerName}</p>
                            <p>{selectedBillingAddress.BAddress1}</p>
                            <p>
                              {selectedBillingAddress.BCity}
                              {selectedBillingAddress.BState}
                              {selectedBillingAddress.BCountry}
                            </p>
                          </div>
                        )}
                      </div>
                      {selectedBillingAddress &&
                        selectedBillingAddress.BCustomerName != null && (
                          <div className="chekout_contact_change">
                            <button
                              className="frm_button"
                              onClick={handlebillAddressChangeButton}
                            >
                              Change Address
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="checkout_shipping_body">
                    {showBillAddress && billingAddresses.length > 0 && (
                      <div className="checkout_shipping_form_inside">
                        <div className="checkout_shipping_body">
                          {billingAddresses.map((address, index) => (
                            <div key={index} className="shipping_address_item">
                              <div className="shipping_address_details">
                                <h4> {address.BCustomerName}</h4>
                                <p>
                                  {address.BAddress1}, {address.BCity},
                                  {address.BState}, {address.BCountry}
                                </p>
                              </div>
                              <button
                                onClick={() =>
                                  handleBillingAddressChange(index)
                                }
                              >
                                Select Address
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Display selected billing address details */}
                </div>
              </div>
            )}
            {shippingAddresses.length > 0 && (
              <div className="shipping_address_detail">
                <div className="chekout_contact_title">
                  <h3>Shipping Address</h3>
                </div>
                <div className="checkout_shipping_form">
                  <div className="checkout_contact_head">
                    <div className="chekout_contact_icon">
                      <img src={rebox} />
                    </div>
                    <div className="space-between-checkout">
                      <div className="chekout_contact_title">
                        <h3>Contact info</h3>
                        {selectedShippingAddress && (
                          <div className="selected_address">
                            {/* <h4>Selected Shipping Address:</h4> */}
                            <p>{selectedShippingAddress.SCustomerName}</p>
                            <p>{selectedShippingAddress.SAddress1}</p>
                            <p>
                              {selectedShippingAddress.SCity}
                              {selectedShippingAddress.SState}
                              {selectedShippingAddress.SCountry}
                            </p>
                          </div>
                        )}
                      </div>
                      {selectedShippingAddress &&
                        selectedShippingAddress.SCustomerName != null && (
                          <div className="chekout_contact_change">
                            <button
                              className="frm_button"
                              onClick={handleAddressChangeButton}
                            >
                              Change Address
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="checkout_shipping_body">
                    {/* Dropdown for selecting shipping address */}

                    {showShippingAddress && shippingAddresses.length > 0 && (
                      <div className="checkout_shipping_form_inside">
                        {/* <div className="checkout_contact_head"> */}
                        {/* <div className="chekout_contact_icon"> */}
                        {/* <img src={rebox} /> */}
                        {/* </div> */}
                        {/* <div className="space-between-checkout"> */}
                        {/* <div className="chekout_contact_title"> */}
                        {/* <h3>Choose Shipping Address:</h3> */}
                        {/* </div> */}
                        {/* </div> */}
                        {/* </div> */}
                        <div className="checkout_shipping_body">
                          {shippingAddresses.map((address, index) => (
                            <div key={index} className="shipping_address_item">
                              <div className="shipping_address_details">
                                <h4>{address.SCustomerName}</h4>
                                <p>
                                  {address.SAddress1}, {address.SCity},
                                  {address.SState}, {address.SCountry}
                                </p>
                              </div>
                              <button
                                onClick={() =>
                                  handleShippingAddressChange(index)
                                }
                              >
                                Select Address
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* {!showBillingAddress && showShippingAddress ? <p className="giv_error">Please Sellect Address</p> : ""} */}
              </div>
            )}
          </div>
          <div className="checkout_order_summary">
            <div className="cart_list checkout">
              {api_data.map((item) => {
                return (
                  <>
                  {
                    item.Carts.length > 0 && item.Carts.map((cartItem)=> {
                      return(
                        <>
                  <div className="cart_item" key={cartItem.ProductID}>
                    <div className="cart_img checkout">
                      {/* Use item.ProductImage here */}
                      <img src={getImageUrl(item)} alt={cartItem.ProductName} />
                    </div>
                    <div className="cart_info">
                      <div className="cart_info1 checkout">
                        <div className="cart_detail">
                          <div className="cart_title">
                            <h3>{cartItem.ProductName}</h3>
                            {cartItem.randomnumber != "" &&
                              cartItem.randomnumber != null && (
                                <p>
                                  {cartItem?.randomnumber?.map(
                                    (randomnumber, i) => {
                                      return (
                                        <span className="sm-txt" key={i}>
                                          {randomnumber.name}(
                                          {randomnumber.value}){" "}
                                        </span>
                                      );
                                    }
                                  )}
                                </p>
                              )}
                            {
                              cartItem?.CartSetupPrice?.length > 0 && (
                                <>
                                  <h3 style={{ marginTop: "12px" }}>
                                    Additional Cost:
                                  </h3>
                                  <p>
                                    {cartItem.CartSetupPrice.filter(
                                      (setp) =>
                                        parseFloat(
                                          setp.QTY
                                        ) > 0
                                    ).map((setp, i) => (
                                      <span className="sm-txt" key={i}>
                                        {setp.ProductSetupPrice.SetupName} (
                                        {parseFloat(setp.QTY)}){" "}
                                      </span>
                                    ))}
                                  </p>
                                </>
                              )}
                          </div>
                          <div className="cart_price">
                            <h5>
                              $
                              {(
                               cartItem.BasePrice * cartItem.QTY
                              ).toFixed(2)}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="cart_info2 checkout">
                        <div className="cart_count checkout">
                          <h4>
                            QTY: {cartItem.QTY} X {cartItem.BasePrice}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="cart_item_space checkout" />
                </>
                      )
                    })
                  }
                  </>
                )
              }
                
              )}
            </div>

            <div className="total-summary checkout">
              <h4>PO# or Order reference number</h4>
              <div className="discount_code">
                <input
                  type="text"
                  value={ponumber}
                  name="PO"
                  id=""
                  onChange={(e) => {
                    setponumber(e.target.value);
                    setponumber_err("");
                  }}
                />
                <p style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>
                  {ponumber_err}
                </p>
              </div>
              <h4>Discount code</h4>
              <div className="discount_code">
                <input
                  type="text"
                  value={couponName}
                  name="CouponName"
                  id=""
                  onChange={(e) => {
                    setcouponName(e.target.value);
                  }}
                />
                <button
                  className={
                    couponName ? "active-color-size-qty-btn" : "bg_gray"
                  }
                  disabled={!couponName}
                  onClick={applyCoupon}
                >
                  Apply
                </button>
              </div>
              <div className="cart_total checkout">
                <div className="cart_title">
                  <h3>Order Summary</h3>
                </div>
                <div className="cart_total_list">
                  <div className="cart_count1">
                    <h3>Subtotal</h3>
                    <h4>${parseFloat(Subtotal).toFixed(2)}</h4>
                  </div>
                  <hr className="cart_total_hr" />
                  {getedCouponValue > 0 && (
                    <>
                      <div className="cart_count1">
                        <h3>
                          Coupon Discount : "{couponName1}({getedCouponValue}%)"
                        </h3>
                        <h4>-${priceAfterDiscount.toFixed(2)}</h4>
                      </div>
                      <hr className="cart_total_hr" />
                    </>
                  )}
                  {getedCouponValue > 0 ? (
                    <div className="cart_count1">
                      <h3>GST ({gstRate}%)</h3>
                      <h4>${gstWithCoupen.toFixed(2)}</h4>
                    </div>
                  ) : (
                    <div className="cart_count1">
                      <h3>GST ({gstRate}%)</h3>
                      <h4>${parseFloat(gstAmount).toFixed(2)}</h4>
                    </div>
                  )}

                  <hr className="cart_total_hr" />
                  {getedCouponValue > 0 ? (
                    <div className="cart_count1">
                      <h3>Order total</h3>
                      <h4>
                        $
                        {(
                          parseFloat(Subtotal) -
                          (Subtotal * getedCouponValue) / 100 +
                          parseFloat(gstWithCoupen)
                        ).toFixed(2)}
                      </h4>
                    </div>
                  ) : (
                    <div className="cart_count1">
                      <h3>Order total</h3>
                      <h4>
                        $
                        {(
                          parseFloat(Subtotal) -
                          (Subtotal * getedCouponValue) / 100 +
                          parseFloat(gstAmount)
                        ).toFixed(2)}
                      </h4>
                    </div>
                  )}
                </div>
                <button
                  onClick={handleplaceorder}
                  // disabled={!selectedShippingAddress && !selectedBillingAddress}
                  // style={{
                  //   backgroundColor: (!selectedShippingAddress || !selectedBillingAddress)
                  //     ? 'gray'
                  //     : '',
                  //   color: 'white',
                  // }}
                >
                  Place Order
                </button>

                <p>
                  Learn more <a href="#">Taxes</a> and <a href="#">Shipping</a>{" "}
                  information
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
