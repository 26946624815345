import React, { useEffect, useState } from "react";
import {
  addtocart_delete_api,
  addtocart_get_api,
  get_cart_count,
  gstdata_get_api,
} from "../service/api";
import { Link, useNavigate } from "react-router-dom";
import loaderimage from "../assets/img/homeimg/loader.gif";
import Alert from "./Alert";

const CartModel = ({ openCartModal, setOpenCartModal }) => {
  const [gstRate, setGSTRate] = useState(0);
  const [api_data, setapi_data] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [removeId, setRemoveId] = useState("");
  const navigate = useNavigate();
  const fetchGST = async () => {
    try {
      const GSTData = await gstdata_get_api();
      setGSTRate(parseFloat(GSTData.Data.Rate));
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };
  const goToCart = () => {
    navigate("/cart");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const cartApiData = await addtocart_get_api();

        // Ensure that cartApiData.Data is an array before setting the state
        if (Array.isArray(cartApiData.Data)) {
          setapi_data(cartApiData.Data);
          console.log("cart data",cartApiData.Data)
          console.log("api data ", cartApiData.Data[0].Carts);
        } else {
          console.error("API data format is not as expected");
        }
        // Fetch the GST rate
        fetchGST();
      } catch (error) {
        console.error("Error fetching API data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (openCartModal) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "auto";
    }

    return () => {
      document.documentElement.style.overflowY = "auto";
    };
  }, [openCartModal]);
  const [cartData, setCartData] = useState(
    localStorage.getItem("cartItemCount")
  );

  const removeCartItem = async (cartID) => {
    // debugger;
    console.log("open please");
    setShowConfirmBox(true);
    setRemoveId(cartID);
  };
  const getCartData = async () => {
    try {
      const cartApiData = await get_cart_count();
      console.log("cartApiData",cartApiData)
      if(cartApiData.status === 200){
        setCartData(cartApiData.data.CartCount);
        localStorage.setItem("cartItemCount", cartApiData.data.CartCount);
      }
    

  
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };


  const handleDelete = async (id) => {
    try {
      console.log("id for delete ", id);
      const response = await addtocart_delete_api(id);
      // handleExceptionSuccessMessages(response.Message);
      // Update the cart items after removal
      const updatedCartItems = api_data.map((item) => {
        // Remove the cart item with the matching CartID from each item's Carts array
        const updatedCarts = item.Carts.filter((cartItem) => cartItem.CartID !== id);
        
        // Return the item with the updated Carts array
        return {
          ...item,
          Carts: updatedCarts
        };
      }).filter((item) => item.Carts.length > 0); 
      setapi_data(updatedCartItems);
      setCartData(updatedCartItems.length);

      // getCartData()
      setShowConfirmBox(false);
await getCartData()
      // Update cart item count in local storage
     
    } catch (error) {
      console.error("Error removing cart item:", error);
    }
  };

  const closeSidebar = () => {
    setOpenCartModal(false);
  };
  const openSidebar = () => {
    setOpenCartModal(true);
  };

  const getImageUrl = (item) => {
    return (
      process.env.REACT_APP_API_URL + item.ProductImage ||
      process.env.REACT_APP_API_URL + "default.jpg"
    );
  };
  const calculateSubtotal = (item) => {
   
    const subtotal = item.Carts.reduce((total, cartItem) => {
      const qty = cartItem.QTY || 0;
      const basePrice = parseFloat(cartItem.BasePrice) || 0; 
      return total + (qty * basePrice);
    }, 0);
  
    return subtotal.toFixed(2);
  };

  const calculateGST = (subtotal) => {
    const gstTotal = subtotal * (gstRate / 100);
    return gstTotal.toFixed(2);
  };
  const handleRedirect = (Slug) => {
    if (Slug) {
      console.log("slug", Slug);
      // Redirect to the product details page with the slug
      window.location.href = `/product/${Slug}`;
      setOpenCartModal(false);
    } else {
      console.error("Slug is undefined or null");
    }
  };

  return (
    <>
      {openCartModal && (
        <>
          {showConfirmBox && (
            <Alert
              message="Are you sure you want to remove this item from the cart?"
              showConfirmBox={showConfirmBox}
              setShowConfirmBox={setShowConfirmBox}
              onConfirm={handleDelete}
              id={removeId}
            />
          )}
          <div className="cont">
            <div className="backdrop" onClick={closeSidebar}></div>
            <div className="side_bar">
              <span id="closeBtn" onClick={closeSidebar}>
                &times;
              </span>
              {loading && (
                <img
                  src={loaderimage}
                  style={{
                    position: "absolute",
                    top: "40vh",
                    left: "141px",
                    width: "80px",
                  }}
                  alt="loader"
                />
              )}
              {!loading && api_data.length === 0 && (
                <div className="empty_cart_div">
                  <h3>Please add any Item First</h3>
                  <button
                    className="go_to_cart_button"
                    onClick={() => navigate("/")}
                  >
                    GO TO HOME
                  </button>
                </div>
              )}
              {api_data.length > 0 && (
                <div className="content">
                  <div>
                    {api_data.map((item) => {
                      return (
                        <>
                        {
                          item.Carts.map((cartItem)=> {
                            return (
                              <div className="item">
                                {/* {console.log(`/product/${item.Slug}`)} */}
                                <div className="img_section">
                                  <img
                                    src={getImageUrl(item)}
                                    alt={cartItem.ProductName}
                                    height={70}
                                    width={50}
                                  />
                                  <div className="label_wrapper">
                                    <div className="top">
                                      <p
                                        className="title_modal"
                                        onClick={() => handleRedirect(item.Slug)}
                                      >
                                        {cartItem.ProductName}{" "}
                                      </p>
                                      <span
                                        className="rmv"
                                        onClick={() =>
                                          removeCartItem(cartItem.CartID)
                                        }
                                      >
                                        &times;
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {cartItem.randomnumber != "" &&
                                  cartItem.randomnumber != null && (
                                    <p>
                                      {cartItem?.randomnumber?.map(
                                        (randomnumber, i) => {
                                          return (
                                            <span className="sm-txt" key={i}>
                                              {randomnumber.name}({randomnumber.value}
                                              ){" "}
                                            </span>
                                          );
                                        }
                                      )}
                                    </p>
                                  )}
                                {
                                  cartItem?.CartSetupPrice?.length > 0 && (
                                    <>
                                      <p
                                        style={{ marginTop: "12px", fontSize: "1vw" }}
                                      >
                                        Additional Cost:
                                      </p>
                                      <p>
                                        {cartItem.CartSetupPrice.filter(
                                          (setp) =>
                                            parseFloat(
                                              setp.QTY
                                            ) > 0
                                        ).map((setp, i) => (
                                          <span className="sm-txt" key={i}>
                                            {setp.ProductSetupPrice.SetupName} (
                                            {parseFloat(setp.QTY)}){" "}
                                          </span>
                                        ))}
                                      </p>
                                    </>
                                  )}
                                <div className="middle">
                                  <p>QTY: {cartItem.QTY}</p>
                                </div>
                                <div className="last">
                                  <span> ${cartItem.BasePrice}</span>
                                  <span>
                                    $
                                    {(
                                      cartItem.BasePrice * cartItem.QTY
                                    ).toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            )
                          })
                        }


                        </>
                      
                       
                      );
                    })}
                  </div>
                  <div>
                    <>
                      <div className="last_part">
                        <p className="qty_total">
                          UNIT QTY TOTAL :{" "}
                          {api_data
                            .reduce(
                              (total, item) =>
                                total + parseFloat(calculateSubtotal(item)),
                              0
                            )
                            .toFixed(2)}{" "}
                        </p>
                      </div>
                      <div className="line"></div>
                      <div className="total">
                        <p>
                          TOTAL.<span>{`(GST ${gstRate.toFixed(2)}%)`}</span>
                        </p>
                        <p>
                          {" "}
                          $
                          {(
                            parseFloat(
                              api_data.reduce(
                                (total, item) =>
                                  total + parseFloat(calculateSubtotal(item)),
                                0
                              )
                            ) +
                            parseFloat(
                              calculateGST(
                                api_data.reduce(
                                  (total, item) =>
                                    total + parseFloat(calculateSubtotal(item)),
                                  0
                                )
                              )
                            )
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="button_div">
                        <button
                          className="go_to_cart_button"
                          onClick={goToCart}
                        >
                          GO TO CART
                        </button>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CartModel;
