import React, { useState, useEffect } from 'react';
import { Header } from "../components/Header.js";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import { Category_Header } from "./../components/category-header.js";
import Validation from '../components/validation.js';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import { redirect, useNavigate } from 'react-router-dom';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { AuthApi } from "../service/api.js";
import Head_CateHead from '../components/Head_CateHead.js';
import { jwtDecode } from 'jwt-decode';


export const Signin = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({})

  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);

  // useEffect(() => {

  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     const decodedToken = jwtDecode(token);
  //     const currentTime = Date.now() / 1000; 
  //     if (decodedToken.exp < currentTime) {

  //       handleLogout(); 
  //     }
  //   }
  // }, []);

  // const handleLogout = () => {
  //   localStorage.clear();
  //   navigate('/signin');
  // };


  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError(ExceptionError => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  function handleExceptionSuccessMessages(res) {
    setSuccessMessages(successMessages => [
      ...successMessages,
      { id: Date.now(), message: res.data.Message },
    ]);
  }

  function clearErrors(id) {
    setExceptionError(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }




  const [values, setValues] = useState(
    {
      Email: '',
      Password: '',

    }
  )

  function handleInput(event) {
    const newObj = { ...values, [event.target.name]: event.target.value }
    setValues(newObj)
  }

  const lastVisitedPage = localStorage.getItem("lastVisitedPage");

  const AuthApi_api_call = async (values) => {

    console.log(values);

    // if (errors.length == 0){
    const res = await AuthApi(values);
    console.log("res sign in", res)

    if (res.status === 200) {
      var data = res.data;
      console.log(data);
      localStorage.setItem("UserID", data.data.UserID);
      localStorage.setItem("RoleID", data.data.RoleID);
      localStorage.setItem("FullName", data.data.FullName);
      localStorage.setItem("MobileNumber", data.data.MobileNumber);
      localStorage.setItem("Email", data.data.Email);
      localStorage.setItem("UserName", data.data.UserName);
      localStorage.setItem("Password", data.data.Password);
      localStorage.setItem("ProfilePic", data.data.ProfilePic);
      localStorage.setItem("LoginCount", data.data.LoginCount);
      localStorage.setItem("UserAccess", data.data.UserAccess);
      localStorage.setItem("PortalEmail", data.data.PortalEmail);
      localStorage.setItem("IsChangePassword", data.data.IsChangePassword);
      localStorage.setItem("IsHolder", data.data.IsHolder);
      localStorage.setItem("IsPortal", data.data.IsPortal);
      localStorage.setItem("IsVisible", data.data.IsVisible);
      localStorage.setItem("CreatedBy", data.data.CreatedBy);
      localStorage.setItem("CreatedOn", data.data.CreatedOn);
      localStorage.setItem("LastModifiedBy", data.data.LastModifiedBy);
      localStorage.setItem("LastModifiedOn", data.data.LastModifiedOn);
      localStorage.setItem("DOB", data.data.DOB);
      localStorage.setItem("Gender", data.data.Gender);
      localStorage.setItem("Bio", data.data.Bio);
      localStorage.setItem("token", data.token);
      handleExceptionSuccessMessages(res);
     
      console.log("data.data.RoleID",data.data.RoleID)
      setTimeout(() => {
        console.log("data.data.RoleID", data.data.RoleID);
  
        if ([1, 2, 0, 3, 4].includes(data.data.RoleID)) {
          if (lastVisitedPage && lastVisitedPage !== "/signin" && lastVisitedPage !== "/signup") {
            
            navigate(lastVisitedPage);
          } else {
         
            navigate("/");
          }
        } else {
       
          navigate("/signin");
        }
      }, 10);
      
    } else {
      handleExceptionError(res.response.data.ErrorMessage)
    }
    return (data);
    // }
  };



  function handleValidation(event) {
    event.preventDefault();
    values.Email = (values.Email).toLowerCase();
    const validationErrors = Validation(values); // Pass the values object directly
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      AuthApi_api_call(values);
    }
  }

  // useEffect(() => {
  //   if (Object.keys(errors).length === 0) {
  //     AuthApi_api_call(values);
  //   }
  // }, [errors]);


  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/')
    }
  }, [])

  return (
    <div>
      <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      <Head_CateHead />
      <div className="container sign_container">
        <Slide_title title="Sign in" className="signin_page_title" />
        <div className="sign_in_wrapper">
          <form onSubmit={handleValidation}>
            <div className="checkout_form_tel">
              <h3 className="required">Email</h3>
              <input type="text" name="Email" id="Email" onChange={handleInput} />
              {errors.Email && <p className='giv_error'>{errors.Email}</p>}
            </div>
            <div className="checkout_form_tel">
              <h3 className="required">Password</h3>
              <input type="Password" name="Password" id="Password" onChange={handleInput} />
              {errors.Password && <p className='giv_error'>{errors.Password}</p>}
              <div className='space_between'>
                <a href="/forgot_pass" className='forgot_password'>Forgot Password</a>
                <span className='forgot_password'>Don't have an account?<a href="/signup" className='signup_cont'> Sign Up</a></span>
              </div>

            </div>
            <button className="frm_button form_submit_form" type="submit">Sign in</button>
            {/* <button className="frm_button form_submit_form" type="submit" value="Sign in" /> */}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}