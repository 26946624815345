// Products.js
import React, { useState, useEffect, useRef } from "react";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "../components/slide-title.js";
import { Spe_offer } from "../components/spe_offer.js";
import filledHeartImage from "./../assets/img/heart.svg";
import unfilledHeartImage from "./../assets/img/heartunfilled.svg";
import loaderImage from "./../assets/img/Spinner.gif";
import search from "./../assets/img/search.svg";
import { Link, useParams } from "react-router-dom";
import {
    portal_map_list,
    home_search
} from "../service/api.js";
import Head_CateHead from "../components/Head_CateHead.js";
import { Pagination } from "@mui/material";
export const Search = () => {
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(slug || ""); // State for the search input
    const [searchArray, setSearchArray] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
    const [showResults, setShowResults] = useState(false);
    const [portalItems, setportalItems] = useState([]);
    const isAuthenticated = !!localStorage.getItem("token");
    const [totalPages, settotalPages] = useState(0);
    const [Pages, setPages] = useState(1);
    useEffect(() => {
        const portallist = async () => {
            try {
                const response = await portal_map_list(); // Fetch category data
                const ItemsData = response.data.data; // Access the 'Data' array
                setportalItems(ItemsData);
            } catch (error) {
                console.error("Error fetching portal list", error);
            }
        };

        if (localStorage.getItem("token")) {
            portallist(); // Call the function to fetch category data
        }
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const fetchSearchResults = async (term = searchTerm, currentPage = 1) => {

        setIsLoading(true);
        try {
            console.log("searchTerm in s", searchTerm, term)
            setPages(currentPage)
            const res = await home_search({ Search: term }, currentPage);
            setSearchArray(res.data.Data.Product || []);
            settotalPages(res.data.totalPages);
            setShowResults(true);
        } catch (error) {
            console.error("Error fetching search results:", error);
        } finally {
            setIsLoading(false);
        }
    };
    console.log()

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        fetchSearchResults(e.target.value);
    };
    useEffect(() => {
        if (slug) {
            console.log(slug);
            setSearchTerm(slug);
            fetchSearchResults(slug);
        }
    }, [slug])

    const handlePageChange = (e, value) => {
        console.log("Page clicked:", value);

        console.log("Page clicked:", value);
        setPages(value);
        fetchSearchResults(searchTerm, value);
        window.scrollTo(0, 0);
    };
    return (
        <div className="search">
            <Head_CateHead />
            <div className="collection_wrapper">
                <div className="container">
                    <div className='search-container'>
                        <div className='search-feild'>
                            <input
                                placeholder='Search'
                                value={searchTerm}
                                onChange={handleSearchChange}

                            />
                            <img src={search} className='search-icon' />
                        </div>
                    </div>
                    <Slide_title title={searchTerm} className="collection_title" />
                    <hr />
                    <div className="trend_menu">
                        <div className="trends_menu_list">
                        </div>
                    </div>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <img src={loaderImage} alt="Loading..." />
                        </div>
                    ) : (
                        <div className="products_list">
                            {searchArray && searchArray.length > 0 ? (
                                searchArray.map((product, index) => (
                                    <Link to={`/product/${product.Slug}`} key={index}>
                                        <div className="arrivals_box" key={index}>
                                            <div className="arrivals_img">
                                                <img
                                                    src={
                                                        process.env.REACT_APP_API_URL + product.ProductImage ||
                                                        process.env.REACT_APP_API_URL + "default.jpg"
                                                    }
                                                    alt="Product"
                                                    className="pro_hero_img"
                                                />
                                            </div>
                                            <div className="arrivals_content">
                                                <div className="arrivals_content_title">
                                                    <h3>{product.ProductName}</h3>
                                                    <p className="sku" style={{ marginLeft: '1', fontSize: '0.83vw', color: '#252525' }}>SKU: {product.SKU}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            ) : (
                                <p>No result found on {searchTerm}</p>
                            )}

                        </div>
                    )}
                    {totalPages > 1 && (
                        <div className="trend_pagination" style={{ display: 'block', position: 'relative', paddingBottom: '100px', paddingTop: '50px' }}>
                            <Pagination count={totalPages} page={Pages} shape="rounded" onChange={handlePageChange} />
                        </div>
                    )}
                </div>
            </div>
            {/* <div className="pro_expert_choice">
        <ExpertChoice />
      </div> */}
            <Spe_offer />
            <Footer />
        </div>

    );
};