import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFviewer=()=> {
    const {pdfurl} = useParams()

    return (
        <div>
            <iframe src={process.env.REACT_APP_API_URL + pdfurl} width="100%" height="600px" title="Embedded PDF"></iframe>
        </div>
    );
}

export default PDFviewer;