import axios from "axios";
import { toast } from "react-toastify";
const API_BASE_BACKEND = process.env.REACT_APP_API_URL;

export const AuthApi = async (values) => {
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/auth/login",
            {
                UserName: values.Email,
                Password: values.Password,
            }
        );
        return response;
    } catch (error) {

        return error;
    }
};

export const AuthRegistration = async (billingvalues, values) => {
    try {
        if (billingvalues.ShippingCountry == '') {
            billingvalues.ShippingCustomername = billingvalues.Customername;
            billingvalues.ShippingCountry = billingvalues.Country;
            billingvalues.ShippingState = billingvalues.State;
            billingvalues.ShippingCity = billingvalues.City;
            billingvalues.ShippingPostcode = billingvalues.Postcode;
            billingvalues.ShippingAddress1 = billingvalues.Address1;
            billingvalues.ShippingAddress2 = billingvalues.Address2;
        }

        billingvalues.FullName = values.Firstname + ' ' + values.Lastname;
        billingvalues.MobileNumber = values.MobileNumber;
        billingvalues.Email = values.Email;
        billingvalues.UserName = values.Email;
        billingvalues.RoleID = values.CustomerType;
        billingvalues.Password = values.Password;

        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/auth/signup",
            billingvalues
        );
        return response;
    } catch (error) {
        return error;
    }
};

export const for_pass = async (values) => {
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/auth/forgotpassword",
            {
                UserNameOrEmail: values.UserNameOrEmail,
            }
        );
        return response;
    } catch (error) {
        return error;
    }
};

export const verify_link = async (urltoken) => {
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/auth/forgotpassword/verifylink",
            {
                VerificationLink: urltoken, // Corrected to pass the urltoken directly
            }
        );
        // Check if the response contains an error code indicating an expired URL
        if (response.data && response.data.ErrorCode === "INVALIDEURLEXPIRE") {
            throw new Error("URL expired.");
        }
        return response;
    } catch (error) {
        return error;
    }
};

export const confirm_password = async (urltoken, values) => {
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/auth/forgotpassword/changepassword",
            {
                VerificationLink: urltoken,
                Password: values.Password,
            }
        );
        return response;
    } catch (error) {
        return error;
    }
};

export const Category_listing = async () => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/category/",
            {
                headers: {
                    Authorization: `${token}`, // Include the token in the header
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const sub_category_listing = async (categoryId) => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/category/${categoryId}`,
            {
                headers: {
                    Authorization: `${token}`, // Include the token in the header
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const Products_list_filter = async (filterParams) => {
    const token = localStorage.getItem("token");
    const url = token
        ? `${API_BASE_BACKEND}frontend/api/product/login`
        : `${API_BASE_BACKEND}frontend/api/product/`;

    try {
        const response = await axios.post(url, filterParams, {
            headers: {
                Authorization: token ? `${token}` : "", // Include the token in the header if available
            },
        });
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const Products_list_api = async (productData) => {
    const token = localStorage.getItem("token");
    const url = token
        ? `${API_BASE_BACKEND}frontend/api/product/login`
        : `${API_BASE_BACKEND}frontend/api/product/`;

    try {
        const response = await axios.post(url, productData, {
            headers: {
                Authorization: token ? `${token}` : "", // Include the token in the header if available
            },
        });
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const Get_attribute_list = async () => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/product/getattribute/all`,
            {
                headers: {
                    Authorization: `${token}`, // Include the token in the header
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const Products_detail_api = async (productId) => {
    const token = localStorage.getItem("token");
    const url = token
        ? `${API_BASE_BACKEND}frontend/api/product/login/${productId}`
        : `${API_BASE_BACKEND}frontend/api/product/${productId}`;

    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: token ? `${token}` : "", // Include the token in the header if available
            },
        });
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const home_search = async (searchTerm, Pages) => {
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `${token}`,
        "Content-Type": "application/json",
    };
    console.log(searchTerm);
    const url = token
        ? `${API_BASE_BACKEND}frontend/api/product/login/search?page=${Pages}&limit=12`
        : `${API_BASE_BACKEND}frontend/api/product/search?page=${Pages}&limit=12`;
    try {
        const response = await axios.post(url,
            { Search: searchTerm.Search, },
            {
                headers: headers,
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };

        return error;
    }
};

export const addtocart_api = async (cartParams) => {
    const token = localStorage.getItem("token");
    try {

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/cart",
            cartParams,
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        throw error;
    }
};

export const addtocart_get_api = async () => {
    const token = localStorage.getItem("token");
    try {

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.get(API_BASE_BACKEND + "frontend/api/cart", {
            headers: headers,
        });
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error fetching product list:", error);
        throw error;
    }
};

export const addtocart_delete_api = async (cartID) => {
    const token = localStorage.getItem("token");
    try {

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.delete(
            API_BASE_BACKEND + `frontend/api/cart/${cartID}`,
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error fetching product list:", error);
        throw error;
    }
};

export const gstdata_get_api = async () => {
    const token = localStorage.getItem("token");
    try {

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/cart/getgst",
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("gstdata_get_api:", error);
        throw error;
    }
};

export const wishlist_api = async (ProductID) => {
    const token = localStorage.getItem("token");
    try {

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/product/addwishlist/${ProductID}`,
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("wishlist_get_api:", error);
        throw error;
    }
};

export const wishlist_remove_api = async (ProductID) => {
    const token = localStorage.getItem("token");
    try {

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.delete(
            API_BASE_BACKEND + `frontend/api/product/addwishlist/${ProductID}`,
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("wishlist_remove_api:", error);
        throw error;
    }
};

export const checkout_shippost_api = async (values) => {
    const token = localStorage.getItem("token");
    try {
        const formData = new FormData();
        formData.append("SCustomerName", values.CustomerName);
        formData.append("SCountry", values.Country);
        formData.append("SState", values.State);
        formData.append("SCity", values.City);
        formData.append("SAddress1", values.Address1);
        formData.append("SAddress2", values.Address2);

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.post(
            API_BASE_BACKEND + `frontend/api/myaccount/addshipping`,
            formData, // Include the formData object here
            {
                headers: headers,
            }
        );

        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error checkout_billpost_api:", error);
        throw error;
    }
};

export const checkout_billpost_api = async (values) => {
    const token = localStorage.getItem("token");
    try {
        const formData = new FormData();
        formData.append("BCustomerName", values.CustomerName);
        formData.append("BCountry", values.Country);
        formData.append("BState", values.State);
        formData.append("BCity", values.City);
        formData.append("BAddress1", values.Address1);
        formData.append("BAddress2", values.Address2);
        formData.append("BEmail", values.BillEmail);
        formData.append("BMobileNumber", values.BillMobileNumber);

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.post(
            API_BASE_BACKEND + `frontend/api/myaccount/addbilling`,
            formData, // Include the formData object here
            {
                headers: headers,
            }
        );

        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error checkout_shippost_api:", error);
        throw error;
    }
};

export const checkoutbilladdress2_api_call = async (values) => {
    const token = localStorage.getItem("token");
    try {
        const formData = new FormData();
        formData.append("BCustomerName", values.BCustomerName);
        formData.append("BCountry", values.BCountry);
        formData.append("BState", values.BState);
        formData.append("BCity", values.BCity);
        formData.append("BAddress1", values.BAddress1);
        formData.append("BAddress2", values.BAddress2);
        formData.append("BEmail", values.BBillEmail);
        formData.append("BMobileNumber", values.BBillMobileNumber);

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.post(
            API_BASE_BACKEND + `frontend/api/myaccount/addbilling`,
            formData, // Include the formData object here
            {
                headers: headers,
            }
        );

        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error checkout_shippost_api:", error);
        throw error;
    }
};

export const checkout_shipget_api = async () => {
    const token = localStorage.getItem("token");
    try {
        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/myaccount/addshipping`,
            {
                headers: headers,
            }
        );

        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error checkout_billpost_api:", error);
        throw error;
    }
};

export const checkout_billget_api = async () => {
    const token = localStorage.getItem("token");
    try {
        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/myaccount/addbilling`,
            {
                headers: headers,
            }
        );

        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error checkout_shippost_api:", error);
        throw error;
    }
};

export const checkout_shipget_list_api = async () => {
    const token = localStorage.getItem("token");
    try {
        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/myaccount/getshipping`,
            {
                headers: headers,
            }
        );

        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error checkout_billpost_api:", error);
        throw error;
    }
};

export const checkout_billget_list_api = async () => {
    const token = localStorage.getItem("token");
    try {
        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/myaccount/getbilling`,
            {
                headers: headers,
            }
        );

        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error checkout_shippost_api:", error);
        throw error;
    }
};

export const place_order_api = async (checkoutParams) => {
    const token = localStorage.getItem("token");
    try {
        const header = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/order",
            checkoutParams,
            {
                headers: header,
            }
        );
        toast.success("Order Done")
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };

        console.error("Error fetching product list:", error);
        throw error;
    }
};

export const cart_description_api = async (requestParams) => {
    const token = localStorage.getItem("token");
    try {
        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/cart/addbrief",
            requestParams,
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error fetching product list:", error);
        throw error;
    }
};
export const delete_artwork_api = async (CartID, CartAttachmentID) => {
    const token = localStorage.getItem("token");
    try {
        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
        };

        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/cart/deleteattchment",
            {
                CartID: CartID,
                CartAttachmentID: CartAttachmentID
            },
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error uploading artwork:", error);
        throw error;
    }
};

export const cart_artwork_api = async (requestParams) => {
    const token = localStorage.getItem("token");
    try {
        const headers = {
            Authorization: `${token}`,
            // "Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
        };

        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/cart/addattchment",
            requestParams,
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error uploading artwork:", error);
        throw error;
    }
};

export const upload_profile_pic = async (ProfilePic) => {
    const token = localStorage.getItem("token");
    console.log(ProfilePic)

    try {
        const headers = {
            Authorization: `${token}`,
            // "Content-Type": "application/json",
            "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
        };

        const response = await axios.post(
            API_BASE_BACKEND + `frontend/api/auth/profilepic/`,
            { ProfilePic: ProfilePic },
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error uploading profile pic:", error);
        throw error;
    }
};
export const portal_listing = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/portal/",
            {
                headers: {
                    Authorization: `${token}`, // Include the token in the header
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const portal_subcategory = async (Portalid) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/portal/${Portalid}`,
            {
                headers: {
                    Authorization: `${token}`, // Include the token in the header
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const Portal = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + "service/agency_category_list/1/5",
            {}
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};
export const Product_list = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(API_BASE_BACKEND + "service/school", {});
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};
export const Product_detail_api = async (id) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + `service/agency_product_single/1/${id}`,
            {}
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};
export const giveaway_api = async (values) => {
    const token = localStorage.getItem("token");
    try {
        const formData = new FormData();

        formData.append("FullName", values.full_name);
        formData.append("SchollName", values.school_name);
        formData.append("Position", values.position);
        formData.append("EmailAddress", values.giv_email);
        formData.append("PhoneNumber", values.phone_number);
        formData.append("WhatItemsInterestedYouTheMostOnOurStall", values.interest);
        if (values.newslatter === "No") {
            formData.append("IsNewsLetter", 0);
        } else {
            formData.append("IsNewsLetter", 1);
        }

        const response = await axios.post(
            API_BASE_BACKEND + "/service/campian_add",
            formData,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const basic_info = async (data) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/myaccount/basic_info",
            {
                FullName: data.FullName,
                MobileNumber: data.MobileNumber,
                DOB: data.DOB,
                Gender: data.Gender,
                Bio: data.Bio,
            },
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        } else {
            return error;

        }

    }
};
export const change_pass = async (data) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/myaccount/changepassword",
            {
                OldPassword: data.OldPassword,
                NewPassword: data.NewPassword,
            },
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};
export const add_Baddress = async (data) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/myaccount/addbilling",
            {
                BCustomerName: data.BCustomerName,
                BCountry: data.BCountry,
                BState: data.BState,
                BCity: data.BCity,
                BZipcode: data.BZipcode,
                BAddress1: data.BAddress1,

                BEmail: data.BEmail,
                BMobileNumber: data.BMobileNumber,
            },
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const Update_Baddress = async (data, id) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.put(
            API_BASE_BACKEND + `frontend/api/myaccount/addbilling`,
            {
                UserBillingAddressID: id,
                BCustomerName: data.BCustomerName,
                BCountry: data.BCountry,
                BState: data.BState,
                BCity: data.BCity,
                BZipcode: data.BZipcode,
                BAddress1: data.BAddress1,
                BAddress2: data.BAddress2,
                BEmail: data.BEmail,
                BMobileNumber: data.BMobileNumber,
            },
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const add_Saddress = async (data) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/myaccount/addshipping",
            {
                SCustomerName: data.SCustomerName,
                SCountry: data.SCountry,
                SState: data.SState,
                SCity: data.SCity,
                SAddress1: data.SAddress1,

                SZipcode: data.SZipcode
            },
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const update_Saddress = async (data, id) => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.put(
            API_BASE_BACKEND + `frontend/api/myaccount/addshipping`,
            {
                UserShippingAddressID: id,
                SCustomerName: data.SCustomerName,
                SCountry: data.SCountry,
                SState: data.SState,
                SCity: data.SCity,
                SAddress1: data.SAddress1,
                SAddress2: data.SAddress2,
                SZipcode: data.SZipcode,
            },
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const billind_address_list = async () => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/myaccount/getbilling",
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const billing_address_detail = async (id) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/myaccount/getbilling/${id}`,
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};
export const sipping_address_list = async () => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/myaccount/getshipping",
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const shipping_address_detail = async (id) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/myaccount/getshipping/${id}`,
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const del_billing_add = async (id) => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.delete(
            API_BASE_BACKEND + `frontend/api/myaccount/getbilling/${id}`,
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};
export const del_shipping_add = async (id) => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.delete(
            API_BASE_BACKEND + `frontend/api/myaccount/getshipping/${id}`,
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        return error;
    }
};

export const get_wishlist = async () => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/myaccount/getwishlist/`,
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const del_wishlist = async (productId) => {
    const token = localStorage.getItem("token");

    try {

        const response = await axios.delete(
            API_BASE_BACKEND + `frontend/api/myaccount/getwishlist/${productId}`,
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const get_order_list = async () => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/order/`,

            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};
export const get_order_detail = async (id) => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.get(
            API_BASE_BACKEND + `frontend/api/order/${id}`,

            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const artwork_post = async (data, status, artworkid, orderDetailsId) => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.post(
            API_BASE_BACKEND + `frontend/api/order/artworkapprovereject`,
            {
                OrderID: data.OrderID,
                OrderDetailsID: orderDetailsId,
                OrderArtworkAttachmentID: artworkid,
                Approve: status,
            },
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const ProductRequest = async (ProductID, ProductVariationID, QTY, PrintReady, DeliveryType, PriorityType) => {
    const token = localStorage.getItem("token");
    try {

        const response = await axios.post(
            API_BASE_BACKEND + `frontend/api/productrequest`,
            {
                ProductID: ProductID,
                ProductVariationID: ProductVariationID,
                QTY: QTY,
                PrintReady: PrintReady,
                DeliveryType: DeliveryType,
                PriorityType: PriorityType
            },
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const get_coupon_value_by_name = async (couponName) => {
    const token = localStorage.getItem("token");
    const data = Object.keys(couponName)
        .map((key) => `${key}=${encodeURIComponent(couponName[key])}`)
        .join('&');
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/CouponCode",
            data,
            {
                headers: {
                    Authorization: `${token}`,
                    'content-type': 'application/x-www-form-urlencoded'
                },
            }
        );
        if (response.data.Message === "Coupon Is Active!") {
            toast.success("Coupon Is Applied")
            return { status: true, value: response.data.Data.Value };
        }
        if (response.data.Message != "Coupon Is Active!") {
            toast.error(response.data.Message)
            return { status: false };
        }

    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};
export const coupen_by_name_again = async (couponName) => {
    const token = localStorage.getItem("token");
    const data = Object.keys(couponName)
        .map((key) => `${key}=${encodeURIComponent(couponName[key])}`)
        .join('&');
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/CouponCode",
            data,
            {
                headers: {
                    Authorization: `${token}`,
                    'content-type': 'application/x-www-form-urlencoded'
                },
            }
        );
        if (response.data.Message === "Coupon Is Active!") {
            // toast.success("Coupon Is Applied")
            return { status: true, value: response.data.Data.Value };
        }
        if (response.data.Message != "Coupon Is Active!") {
            toast.error(response.data.Message)
            return { status: false };
        }

    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const portal_map_list = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/portal/portalAuth",
            {},
            {
                headers: {
                    Authorization: `${token}`,
                    'content-type': 'application/x-www-form-urlencoded'
                },
            }
        );
        return response;

    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const AllUnMapcategory = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/category/AllUnMapcategory",
            {},
            {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
            }
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const GetHomePage = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/HomePageSetting/"
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const product_list_category_wise = async (categoryId, page, per_page_record) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/category/categoryProduct/" + categoryId + "?page=" + page + "&limit=" + per_page_record
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const category_details = async (categoryId) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/category/getcategory/" + categoryId
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const subcategory_details = async (categoryid, subcategoryslug) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/category/getsubcategory/" + categoryid + "/" + subcategoryslug
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};
export const terms_condition = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/TermsCondition/getTermsCondition"
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};
export const faq = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            API_BASE_BACKEND + "frontend/api/FAQ/getFAQData"
        );
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const new_arrivals = async (pageNumber) => {
    const token = localStorage.getItem("token");
    try {
        if (pageNumber) {
            const response = await axios.post(
                API_BASE_BACKEND + "frontend/api/product/NewArrivals?page=" + pageNumber + "&limit=24"
            );
            return response;
        } else {
            const response = await axios.post(
                API_BASE_BACKEND + "frontend/api/product/NewArrivals"
            );
            return response;
        }
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const submit_review = async (data,id) => {
    const token = localStorage.getItem("token");
   
    try {
       
            const response = await axios.post(
                API_BASE_BACKEND + `frontend/api/product_review/${id}`,data,
                {
                    headers: {
                        'content-type': 'application/json',
                        'Authorization' :`${token}`
                    },
                }
            );
            return response;
     
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};
export const get_product_review = async (id,page) => {
    const token = localStorage.getItem("token");
   
    try {
       
            const response = await axios.get(
                API_BASE_BACKEND + `frontend/api/product_review/${id}?${page ? "page=" + page + "&" : ""} `
              
            );
            return response;
     
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";
        };
        return error;
    }
};

export const get_cart_count = async () => {
    const token = localStorage.getItem("token");
    try {

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.get(API_BASE_BACKEND + "frontend/api/cart/getcartcount", {
            headers: headers,
        });
        return response;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        console.error("Error fetching product list:", error);
        throw error;
    }
};

export const addtocart_update_qty_api = async (cartParams) => {
    const token = localStorage.getItem("token");
    try {

        const headers = {
            Authorization: `${token}`,
            "Content-Type": "application/json",
        };

        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/cart/cart2",
            cartParams,
            {
                headers: headers,
            }
        );
        return response.data;
    } catch (error) {
        if (token && error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            localStorage.clear();
            window.location.href = "/signin";

        };
        throw error;
    }
};