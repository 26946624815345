import React, { useState, useEffect, useRef } from "react";
import { Footer } from "../components/Footer.js";
import obj3 from "./../assets/img/homeimg/obj3.svg";
import logo from "./../assets/img/Logo.png";
import boy from "./../assets/img/boy.png";
import Box from "@mui/material/Box";
import { Slide_title } from "../components/slide-title.js";
import { ProductBox } from "../components/ProductBox.js";
import { Link } from "react-router-dom";
import {
  product_list_category_wise,
  Products_list_filter,
  Get_attribute_list,
  GetHomePage,
  new_arrivals,
} from "../service/api.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Head_CateHead from "../components/Head_CateHead.js";
import { Spe_offer } from "../components/spe_offer.js";

export const Home = () => {
  const [productArray, setProductArray] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const sliderRef = useRef(null);
  const sliderarrivals = useRef(null);
  const sliderExperts = useRef(null);
  const [slideLoading, setSlideLoading] = useState(false);
  const [HomePageData, setHomePageData] = useState({
    Sliders: [],
    PopularProducts: [],
    NewArrivals: [],
    StartExploring: [],
    TrendingNow: [],
    Offer: [],
  });
  // useEffect(() => {
  //     const getnew = async () =>{
  //         setHomePageData({ "NewArrivals": res.data.data });
  //         console.log(res.data.data);
  //     }
  //     getnew();
  // },[])
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          goToNextSlide();
          break;
        case "ArrowRight":
          goToPrevSlide();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          goToNextArrivals();
          break;
        case "ArrowRight":
          goToPrevArrivals();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const fetchHomepage = async () => {
      try {
        setSlideLoading(true);
        const response = await GetHomePage();
        const res = await new_arrivals();
        // console.log(res)
        console.log(response.data.Data.PopularProducts);

        setHomePageData({
          Sliders: response.data.Data.Sliders,
          PopularProducts: response.data.Data.PopularProducts,
          NewArrivals: res.data.data,
          StartExploring: response.data.Data.StartExploring,
          TrendingNow: response.data.Data.TrendingNow,
          Offer: response.data.Data.Offer[0],
        });
        setSlideLoading(false);
        if (response.data.Data.StartExploring.length > 0) {
          setSelectedCategory(response.data.Data.StartExploring[0].Slug);
        }
      } catch (error) {
        setSlideLoading(false);
        console.error("Error fetching categories", error);
      }
    };
    fetchHomepage();
  }, []);

  useEffect(() => {
    // Fetch and set the product data for the default selected category
    if (selectedCategory !== null) {
      const fetchProductData = async () => {
        try {
          const response = await product_list_category_wise(
            selectedCategory,
            1,
            4
          );
          console.log("response", response);
          if (response.status === 200) {
            console.log(response);
            const productData = response.data.Data;
            setProductArray(productData);
          }
        } catch (error) {
          console.error("Error fetching product list:", error);
        }
      };
      fetchProductData();
    }
  }, [selectedCategory]);


  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  // Define an array of random background colors
  const backgroundColors = ["#3238501a"];

  // Function to select a random background color
  const getRandomBackgroundColor = () => {
    return "rgba(3, 128, 176, 0.05)";
  };

  const settings = {
    infinite: true, // Disable infinite loop
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
  };
  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const newarrivals = {
    infinite: true, // Disable infinite loop
    slidesToShow: 6,
    slidesToScroll: 1,
    centerPadding: "0px",
  };

  const goToNextArrivals = () => {
    sliderarrivals.current.slickNext();
  };

  const goToPrevArrivals = () => {
    sliderarrivals.current.slickPrev();
  };

  const ourexperts = {
    infinite: true, // Disable infinite loop
    slidesToShow: 6,
    slidesToScroll: 1,
    centerPadding: "0px",
  };

  const goToNextourExperts = () => {
    sliderExperts.current.slickNext();
  };

  const goToPrevourExperts = () => {
    sliderExperts.current.slickPrev();
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const banner_nextSlide = () => {
    setCurrentSlide(
      currentSlide === HomePageData.Sliders.length - 1 ? 0 : currentSlide + 1
    );
  };

  const banner_prevSlide = () => {
    setCurrentSlide(
      currentSlide === 0 ? HomePageData.Sliders.length - 1 : currentSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) { banner_nextSlide(); }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide, isHovered]);
  return (
    <div>
      {/* --------------header------------------ */}
      <Head_CateHead />
      {/* ------------slider-area---------- */}
      <div className="slider_section">
        {slideLoading ? (
          <div className="slider-loader"></div>
        ) : (
          HomePageData.Sliders.map((Sliders, index) => (
            <div className="home_page_slider">
              <div
                className={
                  index === currentSlide ? "slider active" : "slider slide"
                }
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}

              >
                <Link
                  to={Sliders.button_link}
                  target={
                    Sliders.New_Tab == 1 && Sliders.New_Tab == "1"
                      ? "_blank"
                      : false
                  }
                >
                  <img
                    src={
                      process.env.REACT_APP_API_URL + Sliders.background_image
                    }
                  ></img>
                </Link>
                {HomePageData.Sliders.length > 1 && (
                  <>
                    <button className="prev" onClick={banner_prevSlide}>
                      &#10094;
                    </button>
                    <button className="next" onClick={banner_nextSlide}>
                      &#10095;
                    </button>
                  </>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      {/* ------------discover-area---------- */}
      <div className="discover-area-wrapper">
        <div className="container">
          <div className="slide-title">
            <div className="discover_heading">
              <h2>Popular Categories</h2>
              <div className="image_div">
                <button className="button_transparent" onClick={goToPrevSlide}>
                  <img
                    src="../arrow_left.svg"
                    className="title_svg"
                    alt="Previous"
                  />
                </button>{" "}
                <button className="button_transparent" onClick={goToNextSlide}>
                  <img
                    src="../arrow_right.svg"
                    className="title_svg"
                    alt="Next"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="discover-list">
            <Slider {...settings} ref={sliderRef}>
              {HomePageData.PopularProducts.map((category) => (
                <Box
                  key={category.id}
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: getRandomBackgroundColor(),
                  }}
                  className="discover-box"
                >
                  <div className="discover-content">
                    <div className="discover-inner-content">
                      <h3>{category.CategoryName}</h3>
                    </div>
                    <Link to={`/category/${category.Slug}`}>
                      <button>Show me all</button>
                    </Link>
                  </div>
                  <div className="discover-img">
                    <img
                      src={
                        process.env.REACT_APP_API_URL + category.CategoryImage
                      }
                      alt={`Category ${category.CategoryName}`}
                    />
                  </div>
                </Box>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* discover-end */}

      {/* New arrivals */}
      <div className="arrivals_wrapper">
        <div className="container">
          <div className="arrivals_title">
            <div className="discover_heading">
              <h2>New arrivals</h2>{" "}
              <div className="image_div">
                <button
                  className="button_transparent"
                  onClick={goToPrevArrivals}
                >
                  <img
                    src="../arrow_left.svg"
                    className="title_svg"
                    alt="Previous"
                  />
                </button>{" "}
                <button
                  className="button_transparent"
                  onClick={goToNextArrivals}
                >
                  <img
                    src="../arrow_right.svg"
                    className="title_svg"
                    alt="Next"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="arrivals_list">
            {/* Render the Slick Slider for "New arrivals" section  */}
            <Slider {...newarrivals} ref={sliderarrivals} initialSlide={2}>
              {HomePageData.NewArrivals.map((product, index) => (
                <Link to={`/product/${product.Slug}`} key={index}>
                  <ProductBox
                    title={product.ProductName}
                    subTitle=""
                    image={product.ProductImage}
                    tag_img={product.tag_img}
                  />
                  <p
                    className="sku"
                    style={{
                      marginLeft: "17px",
                      fontSize: "0.83vw",
                      color: "#252525",
                    }}
                  >
                    SKU: {product.SKU}
                  </p>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* New arrivals end */}

      {/* explore section */}
      <div className="explore-wrapper" id="explore">
        <div className="container">
          <div className="explore-title">
            <Slide_title title="Start exploring" className="also_pur_title" />
          </div>
          <div className="explore-nav">
            <Box
              component="ul"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="explore-nav-menu"
            >
              {HomePageData.StartExploring.map(
                (category, index) =>
                  index < 5 && (
                    <li
                      key={category.Slug}
                      className={
                        selectedCategory === category.Slug ? "active" : ""
                      }
                      onClick={() => handleCategoryClick(category.Slug)}
                    >
                      <img
                        src={
                          process.env.REACT_APP_API_URL + category.CategoryImage
                        }
                        alt={`${category.CategoryName} Icon`}
                      />
                      {category.CategoryName}
                    </li>
                  )
              )}
            </Box>
          </div>

          {selectedCategory !== null && (
            <div className="explore-box-list">
              {productArray.map(
                (product, index) =>
                  index < 4 && (
                    <div className="explore-box" key={product.id}>
                      <div className="explore-box-head">
                        <div className="explore-head-img">
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              product.ProductImage ||
                              process.env.REACT_APP_API_URL + "default.jpg"
                            }
                            alt={product.title}
                          />
                        </div>
                        <div className="explore-head-title">
                          <h3>{product.ProductName}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: product.ProductDescription.slice(0, 150),
                            }}
                          />
                          <Link to={`/product/${product.Slug}`} key={index}>
                            <button className="spe_ex_button">View more</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      </div>
      {/* explore section end */}

      {/* trending section */}
      <div className="trend_wrapper">
        <dic className="container">
          <Slide_title title="What's trending now" className="also_pur_title" />
          <p className="trend_para">
            Discover the most trending products in BeBranded
          </p>
          <hr />
          <div className="arrivals_list">
            {HomePageData.TrendingNow.map((product, index) => (
              <Link to={`/product/${product.Slug}`} key={index}>
                <ProductBox
                  title={product.ProductName}
                  subTitle=""
                  image={product.ProductImage}
                  tag_img={product.tag_img}
                />
                <p
                  style={{
                    marginLeft: "17px",
                    fontSize: "0.83vw",
                    color: "#252525",
                  }}
                >
                  SKU: {product.SKU}
                </p>
              </Link>
            ))}
          </div>
          {/* <div className="trend_bottom_btn">
                        <button>Show me more</button>
                    </div> */}
        </dic>
      </div>
      {/* trending section end */}

      {/* spe offer start */}
      {/* <div className="spe_offer_wrapper">
                <div className="container">
                    <div className="spe_offer">
                        <div className="spe_offer_img">
                            <img src={boy} />
                        </div>
                        <div className="spe_offer_content">
                            <div className="spe_offer_logo">
                                <img src={logo} />
                            </div>
                            <div className="spe_offer_title">
                                
                                <h3>Special offer<br />in kids products</h3>
                            </div>
                            <div className="spe_offer_para">
                                <p>Fashion is a form of self-expression and autonomy at a<br />particular period and place.</p>
                            </div>
                            <div className="spe_offer_button">
                                <button>Discover more</button>
                            </div>
                            <img src="sp_obj.svg" className="spe_obj" />
                            <img src="sp_obj2.svg" className="spe_obj2" />
                            <img src="sp_obj3.svg" className="spe_obj3" />
                        </div>
                    </div>
                </div>
            </div> */}




      {/*<Spe_offer/>*/}
      {/* spe offer end */}

      {/* expert area end */}

      {/* footer area */}
      <Footer />
      {/* footer area end */}
    </div>
  );
};